<template>
  <el-tooltip effect="light" :popper-class="['my-el-tooltip', props.class]" :placement="placement" :offset="offset" :trigger="trigger">
    <slot name="default"></slot>

    <template #content>
      <slot name="content">
        <div class="py-15 text-14 text-gray-light">
          {{ content }}
        </div>
      </slot>
    </template>
  </el-tooltip>
</template>

<script setup>
const props = defineProps({
  placement: {
    type: String,
    default: 'bottom-start'
  },
  offset: {
    type: Number,
    default: 12
  },
  class: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
  trigger: {
    type: String,
    default: 'hover'
  }
})
</script>

<style lang="scss">
.my-el-tooltip {
  &.is-light {
    padding: 0 20px;
    border: 0 none;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &[data-popper-placement] {
      .el-popper__arrow {
        width: 100%;

        &::before {
          background: transparent;
          border-width: 5px;
          border-color: $primary-color;
          border-radius: 0 !important;
          position: absolute;
        }

        &::after {
          width: 100%;
          height: 4px;
          content: '';
          background: $primary-color;
          position: absolute;
          left: 0;
        }
      }
    }

    &[data-popper-placement^='top'] {
      .el-popper__arrow {
        &::before {
          margin-left: -5px;
          left: 50%;
          bottom: 2px;
        }

        &::after {
          border-radius: 0 0 4px 4px;
          top: 1px;
        }
      }
    }
    &[data-popper-placement^='top-start'] {
      .el-popper__arrow {
        &::before {
          left: 10px;
          bottom: 2px;
        }

        &::after {
          border-radius: 0 0 4px 4px;
          top: 1px;
        }
      }
    }
    &[data-popper-placement^='top-end'] {
      .el-popper__arrow {
        &::before {
          right: 10px;
          bottom: 2px;
        }

        &::after {
          border-radius: 0 0 4px 4px;
          top: 1px;
        }
      }
    }

    &[data-popper-placement^='bottom'] {
      .el-popper__arrow {
        &::before {
          margin-left: -5px;
          left: 50%;
          top: 2px;
        }

        &::after {
          border-radius: 4px 4px 0 0;
          bottom: 1px;
        }
      }
    }
    &[data-popper-placement^='bottom-start'] {
      .el-popper__arrow {
        &::before {
          left: 10px;
          top: 2px;
        }

        &::after {
          border-radius: 4px 4px 0 0;
          bottom: 1px;
        }
      }
    }
    &[data-popper-placement^='bottom-end'] {
      .el-popper__arrow {
        &::before {
          right: 10px;
          top: 2px;
        }

        &::after {
          border-radius: 4px 4px 0 0;
          bottom: 1px;
        }
      }
    }
  }
}
</style>
