<template>
  <mydialog :visible="dialogVisible" :title="' '" :width="'1010px'" :destroy-on-close="true" @close="close">
    <div>
      <div class="mb-20 flex justify-between">
        <div class="flex items-center">
          <div class="mr-20 text-22 font-semibold">{{ $t('收藏夹') }}：{{ folder.folderName }}</div>
          <div class="text-gray">{{ $t('更新日期') }}：{{ dayjs(folder.modifyTime * 1000).format('YYYY-MM-DD') }}</div>
        </div>
        <div>
          <div v-if="from === 'index'" class="flex h-30 cursor-pointer items-center justify-center rounded-[4px] border border-blue px-20 text-blue" @click="toAdd">
            <i class="iconfont icon-shoucang"></i>
            <span>{{ $t('点击前往关注该收藏夹') }}</span>
          </div>
        </div>
      </div>
      <div>
        <el-tabs v-model="activeTab" tab-position="top">
          <el-tab-pane :label="$t('饰品走势')" :name="'trend'"> </el-tab-pane>
          <el-tab-pane :label="$t('饰品列表')" :name="'list'"> </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <ClientOnly>
          <collectCharts v-if="activeTab === 'trend'" :folder="folder" :default-filter="{ platform: platform, typeDay: '2', showType: 'charts' }" :width="1010"></collectCharts>
          <detailItemList v-else :list="list"></detailItemList>
        </ClientOnly>
      </div>
    </div>
  </mydialog>
</template>

<script setup>
import collectCharts from './collect-charts.vue'
import mydialog from '@/components/common/dialog.vue'
import detailItemList from './detail-item-list.vue'
import dayjs from 'dayjs'
import { collectFolderAllList } from '@/api/collect'

const props = defineProps({
  title: String,
  visible: Boolean,
  width: String,
  type: String,
  id: String,
  folder: Object,
  platform: String,
  from: String
})

const emit = defineEmits(['close'])

const dialogVisible = ref(props.visible)
const activeTab = ref('')
const list = ref([])

watch(
  () => props.visible,
  val => {
    dialogVisible.value = val
    if (val) {
      activeTab.value = 'trend'
      getList()
    }
  }
)

onMounted(() => {
  // activeTab.value = 'trend'
})

const close = () => {
  dialogVisible.value = false
  emit('close')
}

const getList = async () => {
  const res = await collectFolderAllList({ folderId: props.folder.folderId })
  console.log(res)
  list.value = res.data
}

const toAdd = () => {
  const localePath = useLocalePath()
  dialogVisible.value = false
  emit('close')
  window.open(localePath(`/my/collect?folderId=${props.folder.folderId}&folderName=${props.folder.folderName}&isAdd=true`))
}
</script>

<style></style>
