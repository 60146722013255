<template>
  <div class="base-footer">
    <div class="footer-banner">
      <el-row class="wrapper h-310 pt-33" justify="space-between">
        <div>
          <p class="mb-50 text-28 font-[300] text-white">SteamDT - {{ $t('让数字资产分析更加便捷') }}</p>
          <!-- <p class="mt-12 text-white/60">
              SteamDT is a hobbyist project, not affiliated with Valve or Steam. Steam and the Steam logo are trademarks of Valve Corporation.
              All other trademarks are the property of their respective owners.
            </p>
            <p class="mt-12 text-white/60">
              Some of the tools on this website are integrated by the webmaster from existing online tools, opensource packages, etc., and are
              all released under the original licenses, with the copyright belonging to the original authors or teams.
            </p> -->
          <div class="flex text-16">
            <div class="mr-90">
              <div class="title mb-16 text-white">{{ $t('条款') }}</div>
              <div class="content text-color-lightgray">
                <!-- <div class="mb-10">Terms of Use</div> -->

                <div>
                  <a :href="localePath(`/terms/PrivacyPolicy`)">{{ $t('隐私政策') }}</a>
                </div>
              </div>
            </div>
            <div class="mr-90">
              <div class="title mb-16 text-white">SteamDT</div>
              <div class="content text-color-lightgray">
                <div class="mb-10">
                  <a :href="localePath('/terms/Disclaimer')">{{ $t('免责声明') }}</a>
                </div>
                <div class="mb-10">
                  <a :href="localePath('/terms/AboutUs')">{{ $t('关于我们') }}</a>
                </div>
                <div class="mb-10"><a :href="localePath('/faq')">FAQ</a></div>
              </div>
            </div>
            <div class="mr-90">
              <div class="title mb-16 text-white">{{ $t('联系方式') }}</div>
              <div class="content text-color-lightgray">
                <div class="mb-10">Email:bd@steamdt.com</div>
                <div>{{ $t('QQ群') }}: 884816621</div>
              </div>
            </div>
            <div class="mr-90">
              <div class="title mb-16 text-white">{{ $t('公司信息') }}</div>
              <div class="content text-color-lightgray">
                <div class="mb-10">{{ $t('注册地址') }}: #11-31，VISION EXCHANGE，2 VENTURE DRIVE，SINGAPORE</div>
                <div>{{ $t('我们会在12个小时内回复你的消息') }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- <img src="~/assets/images/footer-good.png" alt="" /> -->
      </el-row>
    </div>

    <div class="footer-data text-color-lightgray flex items-center">
      <img class="mr-12" src="~/assets/images/common/black-logo.svg" alt="" />
      <span class="mr-40">Copyright © 2023-2024 All rights reserved. </span>

      <span class="mr-20">
        <a href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2023043194号-8</a>
      </span>

      <div class="flex items-center">
        Powered by
        <img class="mx-5 inline-block leading-none" src="~/assets/images/layouts/img-steam-logo.svg" alt="" />
        {{ $t('本产品与Valve Corporation及其代理商均无任何关系。') }}
      </div>
    </div>
  </div>
</template>

<script setup>
const showIcp = ref(false)
onMounted(() => {
  console.log('location.host', location.host)
  if (location.hostname === 'steamdt.cn') {
    showIcp.value = true
  }
})
</script>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');

.base-footer {
  .wrapper {
    width: 1400px;
    margin: 0 auto;
  }

  .footer-banner {
    height: 310px;
    background: #272733;
    background-image: url(~/assets/images/layouts/footer-bg.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    img {
      display: block;
      width: 364px;
      height: 230px;
    }
    .content {
      a {
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .footer-data {
    width: 1400px;
    margin: 0 auto;
    color: $light-gray-color;
    padding: 12px 0;

    .data-left span {
      margin: 0 30px 0 0;
    }

    .data-right span {
      margin: 0 0 0 30px;
    }
  }
}
</style>
