<template>
  <div class="section-item h-341 w-343 rounded-[10px] bg-white p-20">
    <div class="flex justify-between border-b border-gray-lighter pb-20">
      <div class="text-16 font-medium">{{ name }}</div>
      <div class="cursor-pointer text-gray hover:text-blue" @click="toSectionAndOpen(selfData[sortType][0])">{{ $t('查看更多') }}>></div>
    </div>
    <div class="flex justify-between border-b border-gray-lighter py-12 text-gray">
      <div class="w-68 text-right">{{ $t('名称') }}</div>
      <div class="flex w-100 cursor-pointer justify-end" @click="changeSort">
        <div>{{ $t('涨跌幅') }}</div>
        <sortarrow :sort-type="sortType"></sortarrow>
      </div>
    </div>
    <div class="pt-10">
      <div v-for="(item, index) in selfData[sortType]" class="flex h-40 cursor-pointer justify-between" @click="toSection(item)">
        <div class="flex">
          <div class="w-40 text-blue">{{ index + 1 }}</div>
          <div>{{ item.name }}</div>
        </div>
        <div :class="item.riseFallRate > 0 ? 'text-red' : 'text-green'">{{ item.riseFallRate }}%</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Sortarrow from '~/components/common/sortarrow.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  k: {
    type: String,
    required: true
  }
})

const selfData = ref({
  ASC: props.data.bottomList,
  DESC: props.data.topList,
  default: props.data.defaultList
})

const { t: $t } = useI18n()

const nameSwitch = () => {
  switch (props.k) {
    case 'hot':
      return $t('热门板块')
      break
    case 'itemTypeLevel1':
      return $t('一级板块')
      break
    case 'itemTypeLevel2':
      return $t('二级板块')
      break
    case 'itemTypeLevel3':
      return $t('三级板块')
      break
  }
}

const formatQueryData = () => {
  const obj = {}
  obj.level = props.k === 'hot' ? 0 : props.k === 'itemTypeLevel1' ? 1 : props.k === 'itemTypeLevel2' ? 2 : 3
  obj.type = props.k === 'hot' ? 'BROAD' : 'ITEM_TYPE'
  return obj
}

const name = ref(nameSwitch())

const sortType = ref('default')

const changeSort = () => {
  sortType.value = sortType.value === 'DESC' ? 'ASC' : 'DESC'
}

const toSection = item => {
  const localePath = useLocalePath()
  const queryObj = formatQueryData()
  window.open(localePath(`/section/?typeVal=${item.typeVal}&level=${item.level}&type=${item.type}`))
}

const toSectionAndOpen = item => {
  const localePath = useLocalePath()
  window.open(localePath(`/section/?typeVal=${item.typeVal}&level=${item.level}&type=${item.type}&openList=true`))
}
</script>

<style scoped lang="scss"></style>
