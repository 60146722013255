<template>
  <div class="common-dialog">
    <el-dialog v-model="dialogOpen" class="common-dialog" :title="props.title || ''" :width="props.width || '30%'" :destroy-on-close="destroyOnClose" @close="close">
      <div class="dialog-wrapper">
        <slot name="default"></slot>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
const emit = defineEmits(['close'])

const props = defineProps({
  title: String,
  visible: Boolean,
  width: String,
  destroyOnClose: {
    type: Boolean,
    default: false
  }
})

watch(
  () => props.visible,
  val => {
    dialogOpen.value = val
  }
)

const dialogOpen = ref(props.visible || false)

const close = () => {
  dialogOpen.value = false
  emit('close')
}
</script>

<style lang="scss">
.common-dialog {
  background: url(../../assets/images/detail/dialog-bg.png) top no-repeat;
  background-size: 100% auto;
  background-color: #fff;
  .el-dialog__header {
    padding-top: 20px;
    padding-right: 0;
    text-align: center;
    .el-dialog__title {
      font-size: 22px;
      font-weight: 600;
    }
  }
}
.dialog-wrapper {
}
</style>
