<template>
  <div class="h-600 overflow-y-auto">
    <div class="mb-10 flex items-center gap-10">
      <div
        class="flex cursor-pointer items-center justify-center rounded-[4px] px-15 py-3"
        :class="selectType === 'all' ? ' border border-blue text-blue' : ''"
        @click="selectType = 'all'"
      >
        {{ $t('全部') }}
      </div>
      <div
        v-for="type in typeList"
        :key="type.value"
        class="flex cursor-pointer items-center justify-center rounded-[4px] px-15 py-3"
        :class="selectType === type.value ? ' border border-blue text-blue' : ''"
        @click="selectType = type.value"
      >
        {{ type.name }}
      </div>
    </div>
    <div class="items-list flex flex-wrap gap-20">
      <!-- <div v-for="(itemInfo, index) in tempList" :key="index">{{ itemInfo }}</div> -->
      <item v-for="(itemInfo, index) in showList" :key="index" :item-info="itemInfo"></item>
    </div>
  </div>
</template>

<script setup>
import item from './detail-item.vue'
const { t: $t } = useI18n()

const props = defineProps({
  list: Array
})

const selectType = ref('all')

const typeList = []
for (let i = 0; i < props.list.length; i++) {
  if (typeList.find(item => item.value === props.list[i].itemType)) {
    continue
  }
  typeList.push({
    name: props.list[i].itemTypeName,
    value: props.list[i].itemType
  })
}

const showList = ref(JSON.parse(JSON.stringify(props.list)))

watch(selectType, val => {
  console.log('selectType', val)
  if (val === 'all') {
    showList.value = props.list
  } else {
    showList.value = props.list.filter(item => item.itemType === val)
  }
})
const tempList = [
  {
    updateTime: '1734505808',
    itemInfoVO: {
      itemId: '553491607',
      itemType: 'CSGO_Type_Pistol',
      defIndexName: 'weapon_deagle',
      name: '沙漠之鹰 | 印花集 (久经沙场)',
      marketHashName: 'Desert Eagle | Printstream (Field-Tested)',
      imageUrl: 'https://steam-center.ok-skins.com//steam/item/730/RGVzZXJ0IEVhZ2xlIHwgUHJpbnRzdHJlYW0gKEZpZWxkLVRlc3RlZCk=.png',
      quality: '普通',
      rarity: '隐秘',
      exterior: '久经沙场',
      qualityEn: 'Normal',
      rarityEn: 'Covert',
      exteriorEn: 'Field-Tested',
      qualityColor: '#888888',
      rarityColor: '#EB4B4B',
      exteriorColor: '#EFAD4D'
    },
    sellPrice: 209.3,
    sellNum: 18461
  },
  {
    updateTime: '1734505773',
    itemInfoVO: {
      itemId: '1315851583760359425',
      itemType: 'CSGO_Type_Pistol',
      defIndexName: 'weapon_deagle',
      name: '沙漠之鹰 | 热处理 (崭新出厂)',
      marketHashName: 'Desert Eagle | Heat Treated (Factory New)',
      imageUrl: 'https://steam-center.ok-skins.com//steam/item/730/RGVzZXJ0IEVhZ2xlIHwgSGVhdCBUcmVhdGVkIChGYWN0b3J5IE5ldyk=.png',
      quality: '普通',
      rarity: '保密',
      exterior: '崭新出厂',
      qualityEn: 'Normal',
      rarityEn: 'Classified',
      exteriorEn: 'Factory New',
      qualityColor: '#888888',
      rarityColor: '#D32CE6',
      exteriorColor: '#228149'
    },
    sellPrice: 434,
    sellNum: 2056
  },
  {
    updateTime: '1734505561',
    itemInfoVO: {
      itemId: '808803559572504576',
      itemType: 'Type_CustomPlayer',
      defIndexName: 'customplayer_terrorist',
      name: '残酷的达里尔爵士（头盖骨）| 专业人士',
      marketHashName: 'Sir Bloody Skullhead Darryl | The Professionals',
      imageUrl: 'https://steam-center.ok-skins.com//steam/item/730/U2lyIEJsb29keSBTa3VsbGhlYWQgRGFycnlsIHwgVGhlIFByb2Zlc3Npb25hbHM=.png',
      quality: '普通',
      rarity: '大师',
      exterior: '未知',
      qualityEn: 'Normal',
      rarityEn: 'Master',
      exteriorEn: 'unknown',
      qualityColor: '#888888',
      rarityColor: '#EB4B4B',
      exteriorColor: '#888888'
    },
    sellPrice: 291.88,
    sellNum: 2576
  }
]
</script>

<style></style>
