<template>
  <div class="transication-dropdown-container">
    <div class="flex justify-between">
      <div class="text-gray">
        <div class="mb-10">{{ $t('近0-24小时成交量') }}</div>
        <div class="mb-10">{{ $t('近24-48小时成交量') }}</div>
        <div>{{ $t('成交量涨幅') }}</div>
      </div>
      <div class="text-black">
        <div class="mb-10 text-right">{{ info.transactionCount24Hours }}</div>
        <div class="mb-10 text-right">{{ info.transactionCount48Hours }}</div>
        <div class="text-right" :class="updownClass(info.transactionCountRate48Hours)">
          {{ info.transactionCountRate48Hours > 0 ? '+' : '' }}{{ info.transactionCountRate48Hours }}%
        </div>
      </div>
    </div>
    <div class="px-0">
      <el-divider direction="horizontal" content-position="left" style="margin-top: 10px; margin-bottom: 10px"></el-divider>
    </div>
    <div style="color: rgb(151, 156, 174)">{{ $t('数据来源为近48小时成交数据') }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  info: {
    type: Object,
    default: () => ({})
  },
  dataRange: {
    type: String,
    default: ''
  }
})

const updownClass = computed(() => num => {
  if (num === 0) return ''
  return num > 0 ? 'text-red' : 'text-green'
})
</script>

<style></style>
