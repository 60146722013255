import { NuxtLink } from '../../.nuxt/components';
<template>
  <div class="base-nav-container h-67" :class="showSearch ? 'search-bg ' : isScrollHeader ? 'headerBg' : ''">
    <div class="base-nav-content flex h-67 justify-between" style="margin: 0 auto">
      <div class="flex">
        <div class="logo mt-11" style="width: 159px; height: 40px">
          <a href="/">
            <img src="~/assets/images/common/logo.svg" style="width: 159px; height: 40px" alt="" />
          </a>
        </div>

        <div class="flex" style="display: none">
          <a :href="localePath(`/`)">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('index') != -1 ? 'active' : ''">
              {{ $t('CS2指数') }}
            </div>
          </a>
          <a :href="localePath(`/trend`)">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('trend') != -1 ? 'active' : ''">
              {{ $t('饰品类型走势') }}
            </div>
          </a>
          <a :href="localePath(`/mkt`)">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('mkt') != -1 ? 'active' : ''">
              {{ $t('饰品市场') }}
            </div>
          </a>
          <a :href="localePath('/cs2/tracker')">
            <div class="base-nav-item pointer mr-40 pt-21 text-16" :class="currentRoutePath.indexOf('tracker') != -1 ? 'active' : ''">
              {{ $t('饰品追踪') }}
            </div>
          </a>
          <a :href="localePath('/inventory')">
            <div
              class="base-nav-item pointer mr-40 pt-21 text-16"
              :class="currentRoutePath.indexOf('inventory') != -1 && currentRoutePath.indexOf('inventory-my') == -1 ? 'active' : ''"
            >
              {{ $t('库存估价') }}
            </div>
          </a>
          <el-dropdown class="nav-dropdown" @command="dropdownJump">
            <span class="base-nav-item flex items-center text-16">
              {{ toolsStr }}
              <i class="iconfont icon-xiangxiahuadong ml-5 text-8"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in tools" :key="item.key" :command="item.command">
                  {{ item.title }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <el-menu style="width: 750px" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="true" :router="true">
          <el-menu-item :index="localePath('/')">{{ $t('首页') }}</el-menu-item>
          <!--          <el-menu-item :index="localePath('/trend')">{{ $t('饰品走势') }}</el-menu-item>-->
          <!-- <el-sub-menu :index="localePath('/trend')">
            <template #title>
              <span>{{ $t('饰品') }}</span>
            </template>
            <el-menu-item :index="localePath('/trend')">{{ $t('饰品走势') }}</el-menu-item>
            <el-menu-item :index="localePath(`/cs2/tracker`)">{{ $t('饰品追踪') }}</el-menu-item>
          </el-sub-menu> -->

          <el-menu-item :index="localePath('/ladders')">{{ $t('排行榜') }}</el-menu-item>
          <el-menu-item :index="localePath('/inventory')">{{ $t('库存估价') }}</el-menu-item>
          <el-menu-item :index="localePath(`/cs2/tracker`)">{{ $t('饰品追踪') }}</el-menu-item>
          <el-menu-item :index="localePath('/mkt')">{{ $t('饰品市场') }}</el-menu-item>
          <el-menu-item :index="localePath('/my/collect')">{{ $t('我的收藏') }}</el-menu-item>
          <el-sub-menu>
            <template #title>
              <span>{{ $t('工具') }}</span>
            </template>
            <el-menu-item :index="localePath('/hanging')">{{ $t('挂刀/搬砖') }}</el-menu-item>
            <el-menu-item :index="localePath('/skindiy')">{{ $t('饰品DIY') }}</el-menu-item>
            <el-menu-item :index="localePath('/selection')">{{ $t('高级选品') }}</el-menu-item>
          </el-sub-menu>

          <!--          <el-menu-item :index="localePath('/classic')">{{ // $t('经典版首页') }}</el-menu-item>-->
        </el-menu>
      </div>
      <ClientOnly>
        <div class="flex">
          <div class="mr-20 flex items-center">
            <div
              class="flex h-46 cursor-pointer cursor-pointer items-center justify-center rounded-[10px] bg-white/10 px-13 text-white transition-all hover:bg-white hover:text-black"
              :class="currentRoutePath.indexOf('mkt') > -1 || currentRoutePath.indexOf('inventory') > -1 || currentRoutePath.indexOf('tracker') > -1 ? 'w-81' : 'w-200'"
              @click="searchSwitch"
            >
              <i class="iconfont icon-sousuo"></i>
              <span
                >&nbsp;{{
                  currentRoutePath.indexOf('mkt') > -1 || currentRoutePath.indexOf('inventory') > -1 || currentRoutePath.indexOf('tracker') > -1
                    ? $t('搜索')
                    : $t('请输入关键词搜索')
                }}</span
              >
            </div>
          </div>

          <Transition name="fade">
            <div v-show="showSearch" class="search-container fixed left-0 top-66 z-10 flex h-600 w-screen justify-center bg-[#000233]/90 pt-50">
              <div style="width: 1400px" class="relative flex justify-center">
                <div
                  class="absolute right-20 top-0 flex h-46 w-46 cursor-pointer items-center justify-center rounded-[10px] bg-white/10 text-gray-light hover:bg-white"
                  @click="showSearch = false"
                >
                  <i class="iconfont icon-guanbi1"></i>
                </div>
                <div class="w-840">
                  <div class="mb-50 flex items-center justify-center">
                    <img src="~/assets/images/common/only-logo.webp" alt="" class="mr-10" />
                    <span class="text-40 text-white">{{ $t('STEAMDT.COM') }}</span>
                  </div>
                  <div class="search-type-list flex">
                    <div
                      v-for="(type, index) in searchTypeList"
                      :key="index"
                      class="search-type mb-20 mr-30 cursor-pointer text-18"
                      :class="selectedType === type.value ? 'text-white' : 'text-white/60'"
                      @click="selectedType = type.value"
                    >
                      {{ type.name }}
                    </div>
                  </div>
                  <div class="mb-20 flex justify-center">
                    <ClientOnly>
                      <el-autocomplete
                        id="searchInput"
                        ref="searchInput"
                        v-model="pageQuery.queryContent"
                        class="common-search mr-10 h-46 w-full self-center"
                        :style="'width: 840px;height: 64px'"
                        :placeholder="searchTypeList.find(i => i.value === selectedType).placeholder"
                        popper-class="my-suggestion-popper"
                        :popper-append-to-body="false"
                        :fetch-suggestions="querySearch"
                        @keyup.enter="handelSearchEnter"
                        @select="handleSelect"
                      >
                        <template #prefix>
                          <div class="flex items-center">
                            <i class="iconfont icon-sousuo1 text-20 text-gray"></i>
                          </div>
                        </template>
                        <template #suffix>
                          <div class="flex h-54 cursor-pointer items-center justify-center rounded-[10px] bg-blue px-32 text-18 text-white" @click="handelSearchEnter">
                            {{ $t('搜索') }}
                          </div>
                        </template>
                        <template #default="{ item, $index }">
                          <div class="flex items-center">
                            <span>{{ $index }}</span>
                            <img :src="item.url" alt="" style="max-width: 40px" /> <span class="ml-5 text-14 text-gray-light">{{ item.name }}</span>
                            <span v-if="item.clickJump" class="text-blue hover:underline">{{ $t('点击跳转>>') }}</span>
                          </div>
                        </template>
                      </el-autocomplete>
                    </ClientOnly>
                  </div>
                  <div v-if="selectedType == 'tracker'">
                    <a href="https://www.yuque.com/zilonggege-h3als/kuwu70/kb3g87413p79771f" target="_blank" class="mr-20 text-white/60 hover:text-white hover:underline">{{
                      $t('饰品磨损示例')
                    }}</a>
                    <a href="https://www.yuque.com/zilonggege-h3als/kuwu70/kb3g87413p79771f" target="_blank" class="mr-20 text-white/60 hover:text-white hover:underline">{{
                      $t('饰品指纹示例')
                    }}</a>
                  </div>
                  <div v-if="selectedType == 'inventory'">
                    <a href="https://www.yuque.com/zilonggege-h3als/kuwu70/rp6emfg6ge1ebfwt" target="_blank" class="mr-20 text-white/60 hover:text-white hover:underline">{{
                      $t('SteamID示例')
                    }}</a>
                    <a href="https://www.yuque.com/zilonggege-h3als/kuwu70/rp6emfg6ge1ebfwt" target="_blank" class="mr-20 text-white/60 hover:text-white hover:underline">{{
                      $t('Steam用户主页链接示例')
                    }}</a>
                  </div>
                  <div v-if="navSearchHistory && navSearchHistory.length > 0">
                    <div class="mb-10 text-20 text-white">{{ $t('搜索历史') }}</div>
                    <div class="search-history-list flex max-h-160 flex-wrap gap-20 overflow-hidden">
                      <div
                        v-for="history in navSearchHistory"
                        :key="history.name"
                        class="flex h-40 cursor-pointer items-center rounded-[10px] bg-white/10 px-10 text-white/60 transition-all hover:bg-white hover:text-black"
                        @click="historyClick(history)"
                      >
                        <span v-if="searchTypes[history.type]?.name">{{ searchTypes[history.type]?.name }}:</span>

                        <img v-if="history.url" :src="history.url" class="max-h-30 max-w-40" alt="" />
                        {{ history?.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <div v-if="showSearch" class="search-mask fixed left-0 top-66 h-screen w-full bg-black/30" @click="showSearch = false"></div>
          <el-dropdown class="flex items-center" popper-class="my-dropdown-popper">
            <div class="el-dropdown-link flex w-70 items-center justify-center">
              <span class="mr-5 text-14 text-white">{{ unitDropdown.text }}</span>
              <i class="iconfont icon-xiangxiahuadong text-5 text-white"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="w-90">
                <el-dropdown-item
                  v-for="(item, index) in commonDictionary?.rates"
                  :key="index"
                  :class="item.currency === unitDropdown.text ? 'active' : ''"
                  @click.stop="chooseUnit(item)"
                >
                  <span class="text">{{ item.currency }}</span>
                  <span class="ml-10">{{ item.icon }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown v-if="false" class="invisible flex items-center" popper-class="my-dropdown-popper">
            <div class="el-dropdown-link flex w-140 items-center justify-center">
              <span class="mr-5 text-14 text-white">{{ priceDropdown.text }}</span>
              <i class="iconfont icon-xiangxiahuadong text-5 text-white"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="w-140">
                <el-dropdown-item
                  v-for="(item, index) in priceDropdown.list"
                  :key="index"
                  :class="item.text === priceDropdown.text ? 'active' : ''"
                  @click.stop="choosePrice(item.text)"
                >
                  <span class="text">{{ item.text }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown class="flex items-center" popper-class="my-dropdown-popper">
            <div class="el-dropdown-link flex w-80 items-center justify-center">
              <span class="mr-5 text-14 text-white">{{ languageDropdown.text }}</span>
              <i class="iconfont icon-xiangxiahuadong text-5 text-white"></i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="w-100">
                <el-dropdown-item
                  v-for="(item, index) in languageDropdown.list"
                  :key="index"
                  :class="item.text === languageDropdown.text ? 'active' : ''"
                  @click.stop="chooseLanguage(item)"
                >
                  <span class="text">{{ item.text }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <loginComponent>
            <div
              v-show="!userInfo?.id"
              class="steam-login flex h-46 w-112 cursor-pointer items-center justify-center self-center justify-self-end rounded-[10px] bg-blue text-white"
              @click="loginVisible = true"
            >
              <i class="iconfont icon-STEAMDT text-24"></i>
              <span class="login-for-steam ml-10 text-16">{{ $t('登录') }}</span>
            </div>
          </loginComponent>

          <div v-if="userInfo?.id" class="flex items-center">
            <ClientOnly>
              <mytooltip :class="['nav-user-info-tooltip', 'anymore']">
                <template #default>
                  <div class="action-bg flex cursor-pointer items-center px-10">
                    <el-avatar :size="30" :src="userInfo.avatar">
                      <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                    </el-avatar>
                    <!-- <div class="ml-5 text-14 text-white opacity-60">{{ userInfo.nickname }}</div> -->
                  </div>
                </template>
                <template #content>
                  <div class="user-info-content px-15 pt-24 text-14">
                    <div class="mb-20 flex">
                      <div class="">
                        <el-avatar :src="userInfo.avatar" :size="50" class="mr-10 h-60 w-60 overflow-hidden rounded-full">
                          <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                        </el-avatar>
                      </div>
                      <div>
                        <div class="mb-10 text-14 font-semibold">{{ userInfo.nickname }}</div>
                        <div class="logout cursor-pointer text-14 text-gray-light">
                          <span class="user-id text-color-lightgray mr-10">ID: {{ userInfo.id }}</span>
                          <i class="iconfont icon-fuzhi1" @click="copyUserId"></i>
                        </div>
                      </div>
                    </div>
                    <div class="tracker-info mb-15 flex h-72 w-310 items-center justify-between px-10 py-15">
                      <div>
                        <div class="mb-5 text-white">
                          <span>{{ $t('饰品追踪剩余次数') }}：</span>
                          <span class="text-14">{{ userInfo.traceTimes }}</span>
                        </div>
                        <div class="text-color-white opacity-80">{{ $t('每日获得5次，凌晨0点重置') }}</div>
                      </div>
                      <div>
                        <div class="text-color-primary relative flex h-30 cursor-pointer items-center rounded-[15px] bg-white px-12 leading-[30px]" @click="copyShare">
                          <img src="../../assets/images/layouts/share-tips.webp" style="position: absolute; top: -25px; left: -45px; width: 95px; max-width: 95px" alt="" />
                          <i class="iconfont icon-lianjie mr-5"></i>
                          <span>{{ $t('分享') }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <a :href="localePath('/my/setting')">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-yonghuxinxiduilie"></i></div>
                          <div class="text-center">{{ $t('个人中心') }}</div>
                        </div>
                      </a>
                      <a :href="localePath('/my/collect')">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-wodeguanzhu"></i></div>
                          <div class="text-center">{{ $t('我的收藏') }}</div>
                        </div>
                      </a>

                      <a :href="userInfo?.authSteamId ? localePath('/inventory/my') : localePath('/my/setting?noSteam=true')">
                        <div class="user-tooltip-item text-color-lightgray h-70 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-wodekucun"></i></div>
                          <div class="text-center">{{ $t('我的库存') }}</div>
                          <div v-if="!userInfo?.authSteamId" class="text-center text-red">{{ $t('前往绑定') }}</div>
                        </div>
                      </a>

                      <!-- <a href="https://www.yuque.com/zilonggege-h3als/kuwu70/yex2l6mrvrnlg87i" target="_blank">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-changjianwenti2"></i></div>
                          <div class="text-center">{{ $t('常见问题') }}</div>
                        </div>
                      </a> -->
                      <!-- <a :href="localePath('/terms/DevPlan')">
                        <div class="user-tooltip-item text-color-lightgray h-61 w-73 cursor-pointer rounded pt-11">
                          <div class="text-center"><i class="iconfont icon-kaifajihua1"></i></div>
                          <div class="text-center">{{ $t('开发计划') }}</div>
                        </div>
                      </a> -->
                    </div>

                    <!-- <div class="">
                      <div class="mb-5 flex justify-between text-14">
                        <div>
                          <span>{{ $t('饰品追踪剩余次数') }}：</span>
                          <span class="text-color-primary text-14">{{ userInfo.traceTimes }}</span>
                        </div>
                        <div>
                          <img src="~/assets/images/layouts/share-tips.webp" alt="" />
                        </div>
                      </div>
                      <div class="text-color-lightgray flex justify-between text-14">
                        <div>{{ $t('每日获得5次，凌晨0点重置') }}</div>
                        <div class="text-color-primary flex items-center" @click="copyShare">
                          <i class="iconfont icon-lianjie mr-5"></i>
                          <span class="cursor-pointer underline">{{ $t('分享链接') }}</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <el-divider style="margin: 15px 0"></el-divider>
                  <div class="mb-10 flex justify-center text-14">
                    <div class="text-color-lightgray hover:bg-primary logout flex cursor-pointer items-center" @click="logout">
                      <i class="iconfont icon-tuichu mr-5"></i>{{ $t('退出登录') }}
                    </div>
                  </div>
                </template>
              </mytooltip>
            </ClientOnly>
          </div>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
import mytooltip from '../common/mytooltip.vue'
import { getCommonDictionary } from '~/api/common'
import { getAutoComletion, userLogout } from '~/api/steam'
import copyShareUrl from '~/utils/copyshareUrl'
import loginComponent from '~/components/layout/login.vue'

const route = useRoute()
const router = useRouter()
const { locale, setLocale, t } = useI18n()
const $t = t
const routeName = route.name
console.log('route', route)
// 创建一个响应式引用来存储当前的路由路径
const currentRoutePath = ref(route.name)
const store = useNuxtStore()
let userInfo = ref({})
const toolsStr = ref($t('工具'))
const activeIndex = route.path.replace('/en', '')

const loginVisible = ref(false)

const searchInput = ref(null)
const tools = [
  {
    key: '1',
    title: $t('选品'),
    command: 'selection'
  },
  {
    key: '2',
    title: $t('饰品DIY'),
    command: 'skindiy'
  }
]
// 使用 watch 监听路由路径的变化
watch(
  () => route.name,
  (newPath, oldPath) => {
    console.log('路由变更:', oldPath, '=>', newPath)
    showSearch.value = false
    // 在这里执行你的逻辑
    currentRoutePath.value = newPath
  }
)
watch(
  () => store.userInfo,
  (newval, oldval) => {
    console.log('watch.userInfo', newval)
    userInfo.value = newval
  }
)

watch(
  () => store.loginDialog,
  (newval, oldval) => {
    console.log('watch.loginDialog', newval)
    if (newval) {
      loginVisible.value = true
    }
  }
)

const { data: commonDictionary } = await useLazyAsyncData(getCommonDictionary, {
  server: false
})
watch(commonDictionary, () => {
  if (!window.localStorage.getItem('commonDictionary') && commonDictionary) {
  }
})

const isScrollHeader = ref(false)
const unitDropdown = reactive({
  text: useCookie('SDT_MonetaryUnit')._value?.currency || 'CNY',
  list: [
    {
      text: 'USD',
      unit: '$',
      left: '10'
    },
    {
      text: 'EUR',
      unit: '€',
      left: '12'
    },
    {
      text: 'CNY',
      unit: '￥',
      left: '7'
    },
    {
      text: 'AUD',
      unit: 'A$',
      left: '7'
    }
  ]
})
const priceDropdown = reactive({
  text: 'C5GAME价格',
  list: [
    {
      text: 'C5GAME价格'
    },
    {
      text: 'BUFF价格'
    },
    {
      text: '悠悠有品价格'
    },
    {
      text: 'IGXE价格'
    }
  ]
})
const languageDropdown = reactive({
  text: (useCookie('i18n_redirected').value && useCookie('i18n_redirected').value == 'en') || useRoute().path.includes('/en') ? 'English' : '中文',
  list: [
    {
      text: 'English',
      lang: 'en'
    },
    {
      text: '中文',
      lang: 'zh'
    }
  ]
})

const searchTypeList = [
  {
    name: $t('所有'),
    value: 'all',
    placeholder: $t('站内搜索(饰品名称、磨损、SteamID等)')
  },
  {
    name: $t('饰品列表'),
    value: 'items',
    placeholder: $t('站内搜索(饰品名称、磨损、SteamID等)')
  },
  {
    name: $t('饰品详情'),
    value: 'item-detail',
    placeholder: $t('站内搜索(饰品名称、磨损、SteamID等)')
  },
  {
    name: $t('饰品追踪'),
    value: 'tracker',
    placeholder: $t('输入饰品磨损或指纹')
  },
  {
    name: $t('库存'),
    value: 'inventory',
    placeholder: $t('输入SteamID或Steam用户主页链接')
  }
]

const searchTypes = {
  items: {
    name: $t('饰品列表'),
    type: 'items'
  },
  inventory: {
    name: $t('库存估价'),
    type: 'inventory'
  },
  tracker: {
    name: $t('饰品追踪'),
    type: 'tracker'
  },
  'item-detail': {
    name: $t('饰品详情'),
    type: 'item-detail'
  }
}

const showSearch = ref(false)
const selectedType = ref('all')

onMounted(() => {
  window.addEventListener('scroll', scrollHeader)
  const token = useCookie('SDT_AccessToken')
  console.log('token', token.value)
  console.log('route', route)
  if (route.path.includes('selection')) {
    toolsStr.value = $t('选品')
  } else if (route.path.includes('skindiy')) {
    toolsStr.value = $t('饰品DIY')
  }
  if (!token.value) {
    console.log('store.userInfo.value', store.userInfo)
    if (store.userInfo?.id) {
      console.log('store.userInfo.value1', store.userInfo)
      myMessage({
        type: 'error',
        message: '登录状态已失效，请重新登录',
        duration: 3000
      })
      store.setUserInfo({})
    }
  } else {
    userInfo.value = store.userInfo
  }
})

function changeTabs(name) {
  console.log('变更路由', name)
  routeName.value = name
}

function logout() {
  userLogout()
  store.setUserInfo({})
  const token = useCookie('SDT_AccessToken')
  const mySteamToken = useCookie('SDT_MySteamToken')
  token.value = null
  mySteamToken.value = null
  myMessage({
    type: 'success',
    message: '退出登录成功',
    duration: 2000,
    onClose: () => {
      if (route.path.includes('/my')) {
        router.push('/')
      } else {
        location.reload()
      }
      // location.reload()
    }
  })
}
async function copyShare() {
  const remainTime = await copyShareUrl()
  myMessage({
    type: 'success',
    message: '分享链接已复制',
    duration: 2000
  })
  userInfo.value = {
    ...userInfo.value,
    traceTimes: remainTime
  }
  store.setUserInfo(userInfo.value)
}

function copyUserId() {
  console.log('userInfo', userInfo)
  copy(userInfo.value.id)
  myMessage({
    type: 'success',
    message: '复制成功',
    duration: 2000
  })
}
function scrollHeader() {
  // 获取页面滚动的距离
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

  // 滚动页面头部
  if (scrollTop > 0) {
    isScrollHeader.value = true
  } else {
    isScrollHeader.value = false
  }
}

// 选择金额单位
function chooseUnit(item) {
  const monetaryUnit = useCookie('SDT_MonetaryUnit')

  monetaryUnit.value = JSON.stringify(item)
  unitDropdown.text = item.currency
  clearNuxtData()
  window.location.reload(true)
}

// 选择价格
function choosePrice(val) {
  priceDropdown.text = val
}

// 选择语言
function chooseLanguage(val) {
  const i18nRedirected = useCookie('i18n_redirected')

  i18nRedirected.value = val.lang

  languageDropdown.text = val.text
  setLocale(val.lang)
  setTimeout(() => {
    getCommonDictionary(1).then(res => {
      location.reload()
    })
  }, 10)
}

function dropdownJump(command) {
  const localePath = useLocalePath()
  window.location.href = localePath(`/${command}`)
  // router.push(localePath(`/${command}`))
}

const pageQuery = ref({
  queryContent: ''
})

const navSearchHistoryStr = useLocalStorage('newNavSearchHistory')
const navSearchHistory = navSearchHistoryStr.value ? JSON.parse(navSearchHistoryStr.value) : []
function handleSelect(item) {
  console.log('pageQuery.value.queryContent', pageQuery.value.queryContent)
  const localePath = useLocalePath()
  const navSearchHistoryCookie = useCookie('newNavSearchHistory')
  navSearchHistoryCookie.value = ''
  const navSearchHistoryStorage = useLocalStorage('newNavSearchHistory')
  let navSearchHistory = {}
  if (navSearchHistoryStorage.value) {
    navSearchHistory.value = JSON.parse(navSearchHistoryStorage.value)
  }
  if (item.clickJump && item.link) {
    item.name = item.queryString
    item.url = ''
    saveSearchHistory(item, item.type)
    location.href = item.link
  } else {
    saveSearchHistory(item, 'item-detail')
    navSearchHistoryStorage.value = JSON.stringify(navSearchHistory.value)
  }
  if (item.marketHashName) {
    location.href = localePath(`/cs2/${item.marketHashName}`)
  }
}

const handelSearchEnter = () => {
  console.log('handelSearchEnter', pageQuery.value.queryContent)
  const queryString = pageQuery.value.queryContent
  if (pageQuery.value.queryContent) {
    const localePath = useLocalePath()
    const steamIdReg = /^\d{17,}$/
    const steamProfileReg = /steamcommunity.com\/profiles\/\d{17}/
    const steamUserReg = /steamcommunity.com\/id\/\w+$/
    const steamUserReg1 = /steamcommunity.com\/id\/\w+\/$/
    const floatReg = /^0.\d+$/
    const fingerPrintReg = /^[a-zA-Z0-9]{64}$/
    if (steamIdReg.test(queryString) || steamProfileReg.test(queryString) || steamUserReg.test(queryString) || steamUserReg1.test(queryString)) {
      saveSearchHistory(
        {
          name: queryString
        },
        'inventory'
      )
      if (queryString.indexOf('steamcommunity.com') !== -1) {
        location.href = localePath(`/inventory/steamdt-inventory${btoa(queryString)}`)
      } else {
        location.href = localePath(`/inventory/${queryString}`)
      }
    } else if (floatReg.test(queryString) || fingerPrintReg.test(queryString)) {
      saveSearchHistory(
        {
          name: queryString
        },
        'tracker'
      )
      location.href = localePath(`/cs2/tracker?search=${queryString}`)
    } else {
      saveSearchHistory(
        {
          name: queryString
        },
        'items'
      )
      location.href = localePath(`/items/?search=${pageQuery.value.queryContent}`)
    }
  }
}
function querySearch(queryString, cb) {
  const localePath = useLocalePath()
  console.log('queryString', queryString, pageQuery.queryContent)
  if (queryString.length < 1) {
    cb([])
  } else {
    const steamIdReg = /^\d{17,}$/
    const steamProfileReg = /steamcommunity.com\/profiles\/\d{17}/
    const steamUserReg = /steamcommunity.com\/id\/\w+$/
    const steamUserReg1 = /steamcommunity.com\/id\/\w+\/$/
    const floatReg = /^0.\d+$/
    const fingerPrintReg = /^[a-zA-Z0-9]{64}$/
    if (steamIdReg.test(queryString) || steamProfileReg.test(queryString) || steamUserReg.test(queryString) || steamUserReg1.test(queryString)) {
      let link = ''
      if (queryString.indexOf('steamcommunity.com') !== -1) {
        link = localePath(`/inventory/steamdt-inventory${btoa(queryString)}`)
      } else {
        link = localePath(`/inventory/${queryString}`)
      }
      cb([
        {
          name: $t('智能识别为库存信息'),
          url: 'https://cdn.ok-skins.com/steam-stock/category/trend-dialog/robot.png',
          clickJump: true,
          notItem: true,
          link: link,
          type: 'inventory',
          queryString: queryString
        }
      ])
    } else if (floatReg.test(queryString) || fingerPrintReg.test(queryString)) {
      let link = localePath(`/cs2/tracker?search=${queryString}`)
      cb([
        {
          name: $t('智能识别为饰品追踪'),
          url: 'https://cdn.ok-skins.com/steam-stock/category/trend-dialog/robot.png',
          clickJump: true,
          notItem: true,
          link: link,
          type: 'tracker',
          queryString: queryString
        }
      ])
    } else if (selectedType.value != 'tracker' && selectedType.value != 'inventory') {
      getAutoComletion({
        content: encodeURIComponent(queryString)
      }).then(res => {
        if (res.data && res.data[0]) {
          cb(res.data)
        } else {
          cb([
            {
              name: $t('未找到相关饰品信息'),
              notItem: true
            }
          ])
        }
      })
    } else {
      cb([])
    }
  }
  // getAutoComletion({
  //   content: encodeURIComponent(queryString)
  // }).then(res => {
  //   if (res.data && res.data[0]) {
  //     cb(res.data)
  //   }
  // })
}

const saveSearchHistory = (item, type = 'item') => {
  item.type = type
  const itemStr = JSON.stringify(item)
  const searchHistory = useLocalStorage('newNavSearchHistory', [])
  let history = Array.isArray(searchHistory.value) ? searchHistory.value : []
  if (history.length > 0) {
    history = history.filter(i => i.name !== item.name)
    history.unshift(item)
    history = history.slice(0, 20)
  } else {
    console.log(2)
    history = [item]
  }

  searchHistory.value = history
}

const historyClick = item => {
  const localePath = useLocalePath()
  if (item.type === 'inventory') {
    location.href = localePath(`/inventory/${item.name}`)
  }
  if (item.type === 'tracker') {
    location.href = localePath(`/cs2/tracker?search=${item.name}`)
  }
  if (item.type === 'items') {
    location.href = localePath(`/items/?search=${item.name}`)
  }
  if (item.type === 'item-detail') {
    location.href = localePath(`/cs2/${item.marketHashName}`)
  }
}

const searchSwitch = () => {
  showSearch.value = !showSearch.value
  if (showSearch.value) {
    setTimeout(() => {
      console.log('searchInput', searchInput.value)
      searchInput.value.focus()
      const dom = document.getElementById('searchInput')
      console.log('searchInput', searchInput.value.inputRef, dom)
      // dom.focus = true
      // searchInput.value.inputRef.focus = true
    }, 100)
    // nextTick(() => {
    //   const dom = document.getElementById('searchInput')
    //   console.log('searchInput', searchInput.value.inputRef, dom)
    //   dom.focus()
    //   searchInput.value.inputRef.focus = true
    // })
  }
}
function menuSelect(index) {
  const localePath = useLocalePath()
  location.href = localePath(index)
  return false
}
</script>

<style lang="scss" scoped>
@import url(~/assets/styles/_color.scss);
.base-nav-container {
  width: 100%;
  // background-color: rgba($color: #fff, $alpha: 0.8);
  z-index: 3;
  position: fixed;
  transition: all 0.3s linear;
  border-bottom: 1px solid rgba($white-color, 0.15);
  background: url('~/assets/images/layouts/nav-bg.png') top;
  background-size: cover;
  &.search-bg {
    background-color: rgba(0, 2, 51, 0.95);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }
  &.headerBg {
    background: url('~/assets/images/header-bgs/index-nav-bg.jpg') no-repeat #2a2d3c;
    border-color: #2a2d3c;
    box-shadow: #12182c 0px 0px 5px;
    // -webkit-backdrop-filter: blur(5px) brightness(0.2);
    // backdrop-filter: blur(5px) brightness(0.2);
  }

  .base-nav-content {
    max-width: 1400px;
    height: 65px;
    .el-menu-demo {
      // width: 628px;
      :deep(.el-menu-item) {
        background-color: transparent !important;
      }
    }

    .nav-dropdown {
      outline: none;
      * {
        outline: none;
      }
      &:hover {
        outline: none;
      }
    }
    .base-nav-item {
      color: $light-blue-color;
      // opacity: 0.9;
      transition: all 0.1s linear;
      height: 100%;
      &.active {
        color: $white-color;
        border-bottom: 2px solid $white-color;
        opacity: 1;
      }
      &:hover {
        color: $white-color;
        border-bottom: 2px solid $white-color;
        opacity: 1;
      }
    }
    .search-container {
      background: rgba(0, 2, 51, 0.9);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(10px);
      // filter: blur(10px);
    }

    &:deep(.common-search) {
      .el-input__wrapper {
        height: 64px;
        background-color: #fff;
        border: none;
        box-shadow: none;
        transition: all 0.3s;
        padding-right: 8px;
        &.is-focus {
        }
      }
    }
    &:deep(.el-menu) {
      height: 67px;
      background-color: transparent;
      border-bottom-color: transparent;
      .el-menu-item {
        color: rgba(#fff, 0.8);
        font-size: 16px;
        // padding: 0 11px;
        &:hover {
          color: #fff;
          background-color: transparent;
        }
        &.is-active {
          color: #fff;
          background-color: transparent;
        }
      }
      .el-sub-menu__title {
        color: #fff;
        font-size: 16px;
        &:hover {
          // color: #fff;
          background-color: transparent;
        }
      }
    }
  }

  .steam-login {
    border: 1px solid $primary-color;
  }

  :deep(.el-dropdown) {
    .el-dropdown-link {
      padding: 0 15px 0 20px;
      margin-right: 10px;
      height: 46px;
      background: rgba(#fff, 0.1);
      color: rgba(#fff, 0.8);
      border-radius: 10px;
      outline: 0 none;
      // opacity: 0.6;
      transition: all 0.3s;
      &:hover {
        opacity: 1;
        background: #fff;
        color: #000;
        span {
          color: #000;
        }
        i {
          color: #000;
        }
        .iconfont {
          transform: rotate(180deg);
        }
      }
    }
  }

  .action-bg {
    height: 46px;
    background: rgba(#fff, 0.1);
    border-radius: 10px;
  }

  .logout {
    &:hover {
      color: $primary-color;
    }
  }
}
</style>
<style lang="scss">
@import url('~/assets/styles/_color.scss');

.nav-user-info-tooltip {
  width: 340px;
  // box-sizing: border-box;
  // padding: 24px 15px 10px 15px !important;
  padding: 0 !important;
  background: url(~/assets/images/layouts/user-tooltip-bg.webp) top no-repeat !important;
  background-color: #fff !important;
  background-size: 100% auto;
  .user-info-content {
    .tracker-info {
      background: url(~/assets/images/layouts/tracker-info-bg.webp) no-repeat !important;
    }
    .icon-fuzhi1 {
      &:hover {
        color: $primary-color;
      }
    }
    .user-tooltip-item {
      &:hover {
        background-color: rgba($alpha: 0.1, $color: #0252d9);
        color: #000;
        .iconfont {
          color: $primary-color;
        }
      }
    }
  }
}
.user-info-content {
}

.my-dropdown-popper {
  background: $white-color !important;
  border: 1px solid $border-gray-color !important;
  box-shadow: 0 0 0;
  margin-top: -12px;

  .el-dropdown-menu {
    padding: 0;
    background-color: $white-color;
    border-radius: 4px;

    &__item {
      font-size: 14px;
      color: $light-gray-color !important;
      line-height: 20px;
      padding: 8px 20px;

      &.active,
      &:hover {
        background: $border-gray-color;

        .text {
          color: $text-black-color;
        }
      }
    }
  }

  .el-popper__arrow {
    display: none;
  }
}
// :deep(.my-suggestion-popper) {
//   background-color: red;
//   li {
//     padding-top: 4px;
//     padding-bottom: 4px;
//   }
// }
.my-suggestion-popper {
  li {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  // background-color: red;
  // :deep(li) {
  //   padding-top: 4px !important;
  //   padding-bottom: 4px;
  // }
}

//.el-menu--popup{
//  min-width: 100px;
//}
</style>
