<template>
  <div>
    <div v-if="type === 'priceDiff'">
      <div :class="updownClass(info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`])" class="mb-10">
        <span class="text-16">
          {{ info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`] > 0 ? '+' : '' }}
          {{ monetaryUnit() }}
          {{ currencyTransform(info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}Price`]) }}
        </span>
        <span>({{ info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`] > 0 ? '+' : '' }}{{ info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`] }}%)</span>
      </div>
      <div class="text-gray">
        {{ info.sellPriceInfoVO[`before${dayTypeEmnu(dayType)}Price`] }}
        <span class="mx-5">>></span>
        {{ info.sellPriceInfoVO.price }}
      </div>
    </div>
    <div v-if="type === 'priceRate'">
      <div :class="updownClass(info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`])" class="mb-10">
        <span class="text-16">{{ info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`] > 0 ? '+' : '' }}{{ info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}`] }}%</span>
        <span>({{ monetaryUnit() }}{{ currencyTransform(info.sellPriceInfoVO[`diff${dayTypeEmnu(dayType)}Price`]) }})</span>
      </div>
      <div class="text-gray">
        {{ monetaryUnit() }}{{ currencyTransform(info.sellPriceInfoVO[`before${dayTypeEmnu(dayType)}Price`]) }}
        <span class="mx-5">>></span>
        {{ monetaryUnit() }}
        {{ currencyTransform(info.sellPriceInfoVO.price) }}
      </div>
    </div>
    <div v-if="type === 'price'">
      <div class="text-16 text-[#FD802C]">{{ monetaryUnit() }}{{ currencyTransform(info.sellPriceInfoVO.price) }}</div>
    </div>
    <div v-if="type === 'sellNumsDiff'">
      <div :class="updownClass(info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Diff`])" class="mb-10">
        <span class="text-16">
          {{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Diff`] }}
        </span>
        <span> ({{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Diff`] > 0 ? '+' : '' }}{{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Rate`] }}%) </span>
      </div>
      <div class="text-gray">
        {{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}`] }}
        <span class="mx-5">>></span>

        {{ info.sellNumsInfoVO.sellNums }}
      </div>
    </div>
    <div v-if="type === 'sellNumsRate'">
      <div :class="updownClass(info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Diff`])" class="mb-10">
        <span class="text-16">
          {{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Diff`] > 0 ? '+' : '' }}
          {{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Rate`] }}%
        </span>
        <span>({{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}Diff`] }})</span>
      </div>
      <div class="text-gray">
        {{ info.sellNumsInfoVO[`sellNums${dayTypeEmnu(dayType)}`] }}
        <span class="mx-5">>></span>

        {{ info.sellNumsInfoVO.sellNums }}
      </div>
    </div>
    <div v-if="type === 'sellNums'">
      <div class="text-16 text-black">{{ info.sellNumsInfoVO.sellNums }}</div>
    </div>
    <div v-if="type === 'transactionCount'">
      <div class="text-16 text-black">{{ info.transactionCountInfoVO[`transactionCount${dayTypeEmnu(dayType)}`] }}</div>
    </div>
    <div v-if="type === 'transactionAmount'">
      <div class="text-16 text-black"><counter :val="info.transactionAmountInfoVO[`transactionAmount${dayTypeEmnu(dayType)}`]" :decimal-places="2"></counter></div>
    </div>
    <div v-if="type === 'hotCount'">
      <div class="mb-10 text-red">
        <i class="iconfont icon-huo mr-5"></i>
        <span class="text-16">{{ info.hotVO?.hotCount }}</span>
      </div>
      <div class="text-gray">
        <span>{{ $t('热度排名') }} #{{ info.hotVO?.hotRank || '200+' }}</span>
        <span v-if="info.hotVO?.hotRankChange !== 0">/ {{ info.hotVO?.hotRankChange > 0 ? $t('近期上升') : $t('近期下降') }} {{ info.hotVO?.hotRankChange }} {{ $t('名') }}</span>
        <span v-if="info.hotVO?.hotRankChange === 0 && info.hotVO?.hotRankKeepDays >= 3">{{ $t('持续{days}天', { days: info.hotVO?.hotRankKeepDays }) }}</span>
      </div>
    </div>
    <div v-if="type === 'hotUp'">
      <div class="">
        <div class="mb-10" :class="[info.hotVO?.hotRankChange > 0 ? 'text-red' : '', info.hotVO?.hotRankChange < 0 ? 'text-green' : '']">
          <span class="mr-5"
            ><i v-if="info.hotVO?.hotRankChange > 0" class="iconfont icon-lianxushangsheng"></i><i v-if="info.hotVO?.hotRankChange < 0" class="iconfont icon-lianxuxiajiang"></i>
            {{ info.hotVO?.hotRankChange === 0 ? '-' : '' }}
          </span>
          <span>{{ info.hotVO?.hotRankChange }}</span>
        </div>
        <div class="text-gray">
          <span class="mr-10">{{ $t('热度排名') }} #{{ info.hotVO?.hotRank || '200+' }}</span>
          <span>{{ $t('当前热度') }}: <i class="iconfont icon-huo"></i> {{ info.hotVO?.hotCount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dayTypeEmnu } from '~/constants/ladders/config'
import counter from '~/pages/indexcomponents/counter.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'priceDiff'
  },
  info: {
    type: Object,
    default: () => ({})
  },
  dayType: {
    type: String,
    default: '1Days'
  }
})

const updownClass = num => {
  if (num === 0) return ''
  return num > 0 ? 'text-red' : 'text-green'
}
</script>

<style lang="scss"></style>
