<template>
  <div class="rounded-[10px] bg-white p-20 pt-0">
    <div class="mb-20 flex justify-between p-10">
      <el-tabs v-model="selectedCollectType" class="index-collect-tab">
        <el-tab-pane :label="$t('热门收藏夹')" name="hot"></el-tab-pane>
        <el-tab-pane v-if="isLogin" :label="$t('个人收藏夹')" name="self"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="relative flex items-center justify-between">
      <div>
        <!-- <i class="iconfont icon-yuanjiantou_xiangzuo_mian cursor-pointer text-30 text-[#C3CAD7] transition-all hover:text-[#AAC4F1]"></i> -->
        <i class="iconfont icon-yuanjiantou_xiangzuo_mian cursor-pointer text-30 text-[#C3CAD7] transition-all hover:text-[#AAC4F1]" @click="scrollLeft"></i>
      </div>
      <div class="relative px-10">
        <div class="overflow-x-auto overflow-y-visible" style="width: 1250px">
          <div v-if="selectedCollectType === 'hot'" class="flex gap-10">
            <template v-for="folder in collectList" :key="folder.folderId">
              <div
                v-if="folder.folderCount > 0 && folder.isAnalyed"
                class="collect-item w-264 cursor-pointer rounded-[10px] bg-blue-50 p-10"
                style="flex: 0 0 auto"
                @click="showDetailChart(folder)"
              >
                <div class="mb-10">
                  <span class="mr-10 text-16 font-medium"
                    >{{ folder.folderName }} <span class="text-14"> ({{ folder.folderCount }})</span></span
                  >
                  <ClientOnly>
                    <el-tooltip placement="bottom">
                      <template #content>
                        <div>{{ $t('创建者') }}: {{ folder.username }}</div>
                        <div>{{ $t('来源收藏夹') }} : {{ folder.folderName }}</div>
                        <div>{{ $t('点击复制收藏夹关注码') }}</div>
                      </template>
                      <i class="iconfont icon-fuzhi1 cursor-pointer text-gray hover:text-blue" @click.stop="copyShare(folder.shareCode)"></i>
                    </el-tooltip>
                  </ClientOnly>
                </div>
                <div class="mb-10 flex justify-between">
                  <div>
                    <div class="mb-10 text-20" :class="folder.trend.riseFallDiff > 0 ? 'text-red' : 'text-green'">{{ folder.trend.marketIndex }}</div>
                    <div :class="folder.trend.riseFallRate > 0 ? 'text-red' : 'text-green'">
                      <span class="mr-5">{{ folder.trend.riseFallDiff > 0 ? '+' : '' }}{{ (folder.trend.riseFallRate * 100).toFixed(2) }}%</span>
                      <span>[{{ folder.trend.riseFallDiff > 0 ? '+' : '' }}{{ folder.trend.riseFallDiff }}]</span>
                    </div>
                  </div>
                  <div>
                    <ClientOnly>
                      <commonChart
                        :id="`chart-${Math.random().toString(36).substr(2, 9)}`"
                        :data="folder.trend.trendList"
                        :name="folder.folderName"
                        :width="100"
                        :height="44"
                      ></commonChart>
                    </ClientOnly>
                  </div>
                </div>
                <div class="flex items-center text-gray-light">
                  <div class="mr-5">{{ $t('热度') }}:</div>
                  <div>
                    <i class="iconfont icon-huo mr-5"></i>
                    <span>{{ folder.hotCount }}</span>
                  </div>
                </div>
                <el-tooltip v-if="folder.forkInfo" placement="bottom">
                  <template #content>
                    <div class="">
                      <div class="text-16">初始来源：抖音夜信百战</div>
                      <div>点击复制源来源收藏夹关注码</div>
                    </div>
                  </template>
                  <div class="bottom-right-label triangel absolute bottom-0 right-0 cursor-pointer">
                    <i class="iconfont icon-lianjie absolute bottom-3 left-25 text-18 text-white"></i>
                  </div>
                </el-tooltip>
              </div>
              <div v-if="folder.folderCount <= 0" class="collect-item relative w-264 rounded-[10px] bg-blue-50 p-10" style="flex: 0 0 auto">
                <div class="mb-10">
                  <span class="mr-10 text-16 font-medium">{{ folder.folderName }} ({{ folder.folderCount }})</span>
                  <el-tooltip placement="bottom">
                    <template #content> {{ $t('点击复制收藏夹关注码') }} </template>
                    <i class="iconfont icon-fuzhi1 cursor-pointer text-gray hover:text-blue"></i>
                  </el-tooltip>
                </div>
                <div class="mb-10 flex justify-between">
                  <div>
                    <div class="mb-10 text-20 text-gray">-</div>
                    <div class="text-gray">
                      {{ $t('暂无饰品数据') }}
                    </div>
                  </div>
                  <div>
                    <ClientOnly>
                      <!-- <commonChart :id="`chart-${Math.random().toString(36).substr(2, 9)}`" :data="history" :name="$t('1')" :width="100" :height="44"></commonChart> -->
                    </ClientOnly>
                  </div>
                </div>
                <div class="flex items-center text-gray-light">
                  <div class="mr-5">{{ $t('热度') }}:</div>
                  <div>
                    <i class="iconfont icon-huo mr-5"></i>
                    <span>0</span>
                  </div>
                </div>
              </div>
              <div v-if="folder.folderCount > 0 && !folder.isAnalyed" class="collect-item relative w-264 rounded-[10px] bg-blue-50 p-10" style="flex: 0 0 auto">
                <div class="mb-10">
                  <span class="mr-10 text-16 font-medium">{{ folder.folderName }} ({{ folder.folderCount }})</span>
                  <el-tooltip placement="bottom">
                    <template #content> {{ $t('点击复制收藏夹关注码') }} </template>
                    <i class="iconfont icon-fuzhi1 cursor-pointer text-gray hover:text-blue"></i>
                  </el-tooltip>
                </div>
                <div class="mb-10 flex justify-between">
                  <div>
                    <div class="mb-10 text-20 text-gray">-</div>
                    <div class="text-blue">
                      {{ $t('饰品数据生成中...') }}
                    </div>
                  </div>
                  <div>
                    <ClientOnly>
                      <!-- <commonChart :id="`chart-${Math.random().toString(36).substr(2, 9)}`" :data="history" :name="folder.folderName" :width="100" :height="44"></commonChart> -->
                    </ClientOnly>
                  </div>
                </div>
                <div class="flex items-center text-gray-light">
                  <div class="mr-5">{{ $t('热度') }}:</div>
                  <div>
                    <i class="iconfont icon-huo mr-5"></i>
                    <span>0</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else class="flex gap-10">
            <template v-for="folder in myCollectList" :key="folder.folderId">
              <div
                v-if="folder.folderCount > 0 && folder.isAnalyed"
                class="collect-item relative w-264 cursor-pointer rounded-[10px] bg-blue-50 p-10"
                style="flex: 0 0 auto"
                @click="showDetailChart(folder)"
              >
                <div class="mb-10">
                  <span class="mr-10 text-16 font-medium"
                    >{{ folder.folderName }} <span class="text-14"> ({{ folder.folderCount }})</span></span
                  >
                  <ClientOnly>
                    <el-tooltip placement="bottom">
                      <template #content>
                        <div>{{ $t('创建者') }}: {{ folder.username }}</div>
                        <div>{{ $t('来源收藏夹') }} : {{ folder.folderName }}</div>
                        <div>{{ $t('点击复制收藏夹关注码') }}</div>
                      </template>
                      <i class="iconfont icon-fuzhi1 cursor-pointer text-gray hover:text-blue" @click.stop="copyShare(folder.shareCode)"></i>
                    </el-tooltip>
                  </ClientOnly>
                </div>
                <div class="mb-10 flex justify-between">
                  <div>
                    <div class="mb-10 text-20" :class="folder.trend.riseFallDiff > 0 ? 'text-red' : 'text-green'">{{ folder.trend.marketIndex }}</div>
                    <div :class="folder.trend.riseFallRate > 0 ? 'text-red' : 'text-green'">
                      <span class="mr-5">{{ folder.trend.riseFallDiff > 0 ? '+' : '' }}{{ (folder.trend.riseFallRate * 100).toFixed(2) }}%</span>
                      <span>[{{ folder.trend.riseFallDiff > 0 ? '+' : '' }}{{ folder.trend.riseFallDiff }}]</span>
                    </div>
                  </div>
                  <div>
                    <ClientOnly>
                      <commonChart
                        :id="`chart-${Math.random().toString(36).substr(2, 9)}`"
                        :data="folder.trend.trendList"
                        :name="folder.folderName"
                        :width="100"
                        :height="44"
                      ></commonChart>
                    </ClientOnly>
                  </div>
                </div>
                <div class="flex items-center text-gray-light">
                  <div class="mr-5">{{ $t('热度') }}:</div>
                  <div>
                    <i class="iconfont icon-huo mr-5"></i>
                    <span>{{ folder.hotCount }}</span>
                  </div>
                </div>
                <el-tooltip v-if="folder.forkInfo" placement="bottom">
                  <template #content>
                    <div class="">
                      <div class="text-16">初始来源：抖音夜信百战</div>
                      <div>点击复制源来源收藏夹关注码</div>
                    </div>
                  </template>
                  <div class="bottom-right-label triangel absolute bottom-0 right-0 cursor-pointer">
                    <i class="iconfont icon-lianjie absolute bottom-3 left-25 text-18 text-white"></i>
                  </div>
                </el-tooltip>
              </div>
              <div v-if="folder.folderCount <= 0" class="collect-item relative w-264 rounded-[10px] bg-blue-50 p-10" style="flex: 0 0 auto">
                <div class="mb-10">
                  <span class="mr-10 text-16 font-medium">{{ folder.folderName }} ({{ folder.folderCount }})</span>
                  <el-tooltip placement="bottom">
                    <template #content> {{ $t('点击复制收藏夹关注码') }} </template>
                    <i class="iconfont icon-fuzhi1 cursor-pointer text-gray hover:text-blue"></i>
                  </el-tooltip>
                </div>
                <div class="mb-10 flex justify-between">
                  <div>
                    <div class="mb-10 text-20 text-gray">-</div>
                    <div class="text-gray">
                      {{ $t('暂无饰品数据') }}
                    </div>
                  </div>
                  <div>
                    <ClientOnly>
                      <!-- <commonChart :id="`chart-${Math.random().toString(36).substr(2, 9)}`" :data="history" :name="$t('1')" :width="100" :height="44"></commonChart> -->
                    </ClientOnly>
                  </div>
                </div>
                <div class="flex items-center text-gray-light">
                  <div class="mr-5">{{ $t('热度') }}:</div>
                  <div>
                    <i class="iconfont icon-huo mr-5"></i>
                    <span>0</span>
                  </div>
                </div>
              </div>
              <div v-if="folder.folderCount > 0 && !folder.isAnalyed" class="collect-item relative w-264 rounded-[10px] bg-blue-50 p-10" style="flex: 0 0 auto">
                <div class="mb-10">
                  <span class="mr-10 text-16 font-medium">{{ folder.folderName }} ({{ folder.folderCount }})</span>
                  <el-tooltip placement="bottom">
                    <template #content> {{ $t('点击复制收藏夹关注码') }} </template>
                    <i class="iconfont icon-fuzhi1 cursor-pointer text-gray hover:text-blue"></i>
                  </el-tooltip>
                </div>
                <div class="mb-10 flex justify-between">
                  <div>
                    <div class="mb-10 text-20 text-gray">-</div>
                    <div class="text-blue">
                      {{ $t('饰品数据生成中...') }}
                    </div>
                  </div>
                  <div>
                    <ClientOnly>
                      <!-- <commonChart :id="`chart-${Math.random().toString(36).substr(2, 9)}`" :data="history" :name="folder.folderName" :width="100" :height="44"></commonChart> -->
                    </ClientOnly>
                  </div>
                </div>
                <div class="flex items-center text-gray-light">
                  <div class="mr-5">{{ $t('热度') }}:</div>
                  <div>
                    <i class="iconfont icon-huo mr-5"></i>
                    <span>0</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <img class="absolute right-10 top-0 h-full" src="https://cdn.ok-skins.com/steam-stock/collect/shadow.webp" alt="" />
      </div>
      <div>
        <i class="iconfont icon-yuanjiantou_xiangzuo_mian1 cursor-pointer text-30 text-[#C3CAD7] transition-all hover:text-[#AAC4F1]" @click="scrollRight"></i>
      </div>
    </div>
  </div>
  <client-only>
    <collectDetail
      :visible="detailChartVisible"
      :folder="actionFolder"
      :default-filter="{ platform: platform, typeDay: '2' }"
      :width="1010"
      :from="'index'"
      :platform="'YOUPIN'"
      @close="detailChartVisible = false"
    ></collectDetail>
    <!-- <mydialog :visible="detailChartVisible" width="1030" center @close="detailChartVisible = false"> </mydialog> -->
  </client-only>
</template>

<script setup>
import { collectFolderStatus, hotCollectFolderList, skinCollectList } from '@/api/collect'
import collectDetail from '../my/collect/components/collect-detail.vue'

const collectList = ref([])
const myCollectList = ref([])
const selectedCollectType = ref('hot')
const pollingList = ref([])

const detailChartVisible = ref(false)
const actionFolder = ref(null)

const isLogin = useCookie('SDT_AccessToken').value

onMounted(() => {
  getSkinCollectList()
  if (isLogin) {
    getMyCollectList()
  }
})

const getSkinCollectList = async () => {
  const res = await hotCollectFolderList({ limit: 10 })
  console.log(res)
  for (let i = 0; i < res.data.length; i++) {
    res.data[i].trend.trendList = res.data[i].trend.trendList.map(item => {
      return [item[0] * 1000, item[1]]
    })
  }

  collectList.value = res.data
}

const copyShare = id => {
  copy(id)
  myMessage({
    type: 'success',
    message: '复制成功',
    duration: 2000
  })
}

const scrollLeft = () => {
  const container = document.querySelector('.overflow-x-auto')
  container.scrollBy({ left: -500, behavior: 'smooth' })
}

const scrollRight = () => {
  const container = document.querySelector('.overflow-x-auto')
  container.scrollBy({ left: 500, behavior: 'smooth' })
}

const showDetailChart = folder => {
  if (selectedCollectType.value === 'self') {
    const localePath = useLocalePath()
    window.open(localePath(`/my/collect?folderId=${folder.folderId}&isMine=true`))
    return
  }
  actionFolder.value = folder
  detailChartVisible.value = true
  console.log('showDetailChart', actionFolder.value)
}
const getMyCollectList = async () => {
  const { data } = await skinCollectList({ platform: 'YOUPIN' })
  console.log('data', data)
  for (let i = 0; i < data.length; i++) {
    data[i].trend.trendList = data[i].trend.trendList.map(item => {
      return [item[0] * 1000, item[1]]
    })
  }
  pollingList.value = data.filter(item => item.folderCount > 0 && !item.isAnalyed).map(item => item.folderId)
  if (pollingList.value.length) {
    setTimeout(() => {
      pollingInfo()
    }, 5000)
  }
  myCollectList.value = data
}

const pollingInfo = () => {
  collectFolderStatus({ platform: 'YOUPIN', ids: pollingList.value }).then(res => {
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].isAnalyed) {
        for (let j = 0; j < myCollectList.value.length; j++) {
          if (myCollectList.value[j].folderId === res.data[i].folderId) {
            myCollectList.value[j] = res.data[i]
            pollingList.value = pollingList.value.filter(item => item !== res.data[i].folderId)
          }
        }
      }
    }
    if (pollingList.value.length) {
      setTimeout(() => {
        pollingInfo()
      }, 5000)
    }
  })
}
</script>

<style scoped lang="scss">
:deep(.index-collect-tab) {
  .el-tabs__header {
    border: none;
    margin-bottom: 0;
  }
  .el-tabs__item {
    padding: 0 20px;
    font-size: 16px;
  }
  .el-tabs__nav-wrap {
    &::after {
      background-color: transparent;
    }
  }
}
</style>
