<template>
  <div class="default-layouts" style="background-color: #e1e5ed; min-width: 1400px">
    <Nav />

    <slot name="banner">
      <Banner />
    </slot>

    <div class="common-container">
      <slot />

      <div v-if="dialogVisible">
        <ItemInspect :item="detailData.item" :list="detailData.list" :dialog-visible="dialogVisible" @close="dialogVisible = false" />
      </div>
    </div>

    <div v-if="hideAgreement !== 1">
      <Agreement @agree="agree" />
    </div>
    <div class="layout-bg"></div>
    <Footer />

    <div class="gx-sidebar">
      <el-backtop :right="100" :bottom="210">
        <i class="iconfont icon-zhiding1 text-color-lightgray text-16"></i>
      </el-backtop>

      <client-only>
        <el-popover placement="left-start" :show-arrow="false" :width="210" popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 16px;">
          <template #reference>
            <div class="sidebar-item flex items-center justify-center">
              <i class="iconfont icon-qq text-color-lightgray text-18"></i>
            </div>
          </template>

          <template #default>
            <div class="flex items-center">
              <img src="~/assets/images/common/interactIcon.webp" alt="" />
              <div class="ml-10 text-black">
                <div class="text-14">{{ $t('SteamDT交流群') }}</div>
                <div class="mt-5 flex items-center">
                  <span class="mr-5 text-12 leading-none">{{ $t('群号：884816621') }}</span>
                  <i class="iconfont icon-fuzhi1 cursor-pointer text-16 leading-none text-gray-light" @click.stop="copyText(884816621)"></i>
                </div>
              </div>
            </div>

            <div class="mt-10">
              <img src="~/assets/images/common/qrcode.webp" alt="" />
            </div>
          </template>
        </el-popover>
      </client-only>

      <client-only>
        <el-popover
          placement="left-start"
          :show-arrow="false"
          :width="110"
          popper-style="box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.29);border: 0 none;padding: 10px 0;min-width: auto;"
        >
          <template #reference>
            <div class="sidebar-item flex items-center justify-center">
              <i class="iconfont icon-gengduo text-color-lightgray text-18"></i>
            </div>
          </template>

          <template #default>
            <!-- <a :href="localePath('/terms/DevPlan')" class="flex h-32 cursor-pointer items-center px-16 text-black hover:bg-blue-light2 hover:text-blue">
              <i class="iconfont icon-kaifajihua text-16 leading-none"></i>
              <span class="ml-5 text-14 leading-none">{{ $t('开发计划') }}</span>
            </a> -->

            <!-- <div class="hover:bg-blue-light2 flex h-32 cursor-pointer items-center px-16 text-black hover:text-blue">
              <i class="iconfont icon-gengxinrizhi text-16 leading-none"></i>
              <span class="ml-5 text-14 leading-none">{{ $t('更新日志') }}</span>
            </div> -->

            <a
              href="https://www.yuque.com/zilonggege-h3als/kuwu70"
              target="_blank"
              class="flex h-32 cursor-pointer items-center px-16 text-black hover:bg-blue-light2 hover:text-blue"
            >
              <i class="iconfont icon-changjianwenti text-16 leading-none"></i>
              <span class="ml-5 text-14 leading-none">{{ $t('常见问题') }}</span>
            </a>
          </template>
        </el-popover>
      </client-only>
      <!-- <ClientOnly>
        <el-dialog v-model="mobileDialog" title="Tips" width="500">
          <span> {{ $t('为获得更好体验，请使用横屏模式') }} </span>
          <template #footer>
            <div class="dialog-footer">
              <el-button @click="mobileDialog = false">{{ $t('取消') }}</el-button>
              <el-button type="primary" @click="mobileDialog = false">{{ $t('确定') }} </el-button>
            </div>
          </template>
        </el-dialog>
      </ClientOnly> -->
    </div>
  </div>
</template>

<script setup>
import Nav from '~/components/layout/nav.vue'
import Banner from '~/components/layout/banner.vue'
import Agreement from '~/components/layout/agreement.vue'
import Footer from '~/components/layout/footer.vue'
import ItemInspect from '~/components/common/itemInspect.vue'
import { copy } from '#imports'
import { getFingerPrint, getshareReward, getUserInfo } from '~/api/steam'

const { t } = useI18n()
const $t = t
const route = useRoute()
const routeName = route.name
const store = useNuxtStore()
const token = useCookie('SDT_AccessToken')
const mySteamToken = useCookie('SDT_MySteamToken', {
  maxAge: 7 * 24 * 60 * 60 * 1000
})
const dialogVisible = ref(false)
const mobileDialog = ref(false)
const detailData = reactive({
  list: [],
  item: {}
})
const hideAgreement = useCookie('SDT_HideAgreement', {
  maxAge: 999999999
})

onMounted(async () => {
  // document.title = "后台管理";
  // console.log(route);
  if (route.query?.invite) {
    getshareReward({ shareCode: route.query?.invite })
  }

  if (route.query?.hash) {
    getFingerPrint({
      id: route.query?.hash
    }).then(res => {
      detailData.item = res.data
      dialogVisible.value = true
    })
  }
  // console.log('mySteamToken.value', mySteamToken.value)
  if (!store.userInfo?.id && token.value) {
    const userInfo = await getUserInfo()
    store.setUserInfo(userInfo.data)
    console.log('userInfo', userInfo)
    mySteamToken.value = userInfo.data?.steamId || userInfo.data?.authSteamId
  } else {
    mySteamToken.value = store.userInfo?.steamId || store.userInfo?.authSteamId
  }

  const bindEmailTips = useCookie('SDT_BindEmailTips')
  if (bindEmailTips.value == 1) {
    bindEmailTips.value = 2
    ElMessageBox.confirm($t('该STEAM账号未绑定邮箱，无法通过邮箱登录'), $t('登录成功'), {
      confirmButtonText: $t('立即前往'),
      cancelButtonText: $t('稍后绑定'),
      type: 'warning'
    })
      .then(() => {
        window.location.href = '/my/setting'
      })
      .catch(() => {})
  }
  // const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent)
  // const cookie = useCookie('SDT_MobileDialog')
  // if (isMobile && !cookie.value) {
  //   alert($t('因STEAMDT展示数据全面，为了您的使用体验，请使用手机横屏浏览'))
  //   cookie.value = true
  // }
})

const agree = () => {
  hideAgreement.value = 1
}

// 复制
const copyText = val => {
  copy(val)
  myMessage({
    message: '复制成功'
  })
}
</script>

<style lang="scss">
.default-layouts {
  overflow: hidden;
  .layout-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1000px;
    z-index: 0;
    background: linear-gradient(180deg, #acc6ff 0%, #e1e5ed 100%);
    border-radius: 8px;
  }
}
.common-container {
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
  z-index: 2;
  min-height: 1000px;
}

.gx-sidebar {
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 10;
  .el-backtop {
    background: $white-color;

    &:hover i {
      color: $primary-color;
    }
  }

  .sidebar-item {
    width: 40px;
    height: 40px;
    background: $white-color;
    box-shadow: var(--el-box-shadow-lighter);
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover i {
      color: $primary-color;
    }
  }
}
</style>
