<template>
  <div class="price-dropdown-container">
    <div class="flex p-10">
      <div>{{ $t('当前价格') }}</div>
      <span class="mr-5">:</span>
      <div class="text-[#FD802C]">{{ moneyUnit }} {{ transformCurrency(info.price) }}</div>
    </div>
    <div class="px-10">
      <el-divider direction="horizontal" content-position="left" style="margin-top: 0px; margin-bottom: 10px"></el-divider>
    </div>

    <div>
      <template v-for="(day, index) in dayKeyList" :key="index">
        <div v-if="info[`before${day.key}Price`]" class="text-color-gray flex items-center justify-between px-10 py-5 hover:bg-[#DDDFE7]">
          <div class="w-120" :class="dayTypeEmnu(dataRange) === day.key ? 'text-blue' : ''">{{ $t('与{daytext}对比', { daytext: day.label }) }}</div>
          <div class="pl-5 text-12 text-[#FD802C]">{{ moneyUnit }}{{ transformCurrency(info[`before${day.key}Price`]) }}</div>
          <div class="w-70 text-right" :class="updownClass(info[`diff${day.key}`])">{{ info[`diff${day.key}`] > 0 ? '+' : '' }} {{ info[`diff${day.key}`] }}%</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { dayTypeEmnu } from '@/constants/ladders/config'
const i18n = useI18n()
const { t } = i18n
const $t = t

const props = defineProps({
  info: {
    type: Object,
    default: () => ({})
  },
  dataRange: {
    type: String,
    default: ''
  }
})
const daykeyLabels = label => computed(() => $t(label)).value
const dayKeyList = [
  { key: '1Days', label: daykeyLabels('1天前') },
  { key: '3Days', label: daykeyLabels('3天前') },
  { key: '7Days', label: daykeyLabels('7天前') },
  { key: '15Days', label: daykeyLabels('15天前') },
  { key: '1Months', label: daykeyLabels('1个月前') },
  { key: '3Months', label: daykeyLabels('3个月前') },
  { key: '6Months', label: daykeyLabels('6个月前') },
  { key: '1Years', label: daykeyLabels('1年前') }
]

const moneyUnit = computed(() => {
  return monetaryUnit()
})

const transformCurrency = computed(() => num => {
  return currencyTransform(num)
})

const updownClass = num =>
  computed(() => {
    if (num === 0) return ''
    return num > 0 ? 'text-red' : 'text-green'
  })
const formateDate = computed(() => date => {
  return dayjs(date * 1000).format('MM-DD HH:mm')
})
</script>

<style lang="scss"></style>
