<template>
  <el-table v-show="tableData?.length > 0" :data="tableData" :show-header="false" style="width: 100%" :row-style="{ height: '122px' }">
    <el-table-column label="排名" width="50">
      <template #default="scope">
        <div class="text-center"><rankLabel :rank="scope.$index + 1"></rankLabel></div>
      </template>
    </el-table-column>
    <el-table-column label="名称" width="800">
      <template #default="scope">
        <div class="flex items-center gap-10">
          <div>
            <div class="item-bg flex h-74 w-109 items-center justify-center">
              <img :src="scope.row.imageUrl" class="max-w-100" alt="" />
            </div>
          </div>
          <div>
            <div class="mb-10 text-black">{{ scope.row.name }}</div>
            <div class="flex items-center gap-30 text-gray">
              <div v-for="platform in scope.row.platformList" :key="platform.platformEnum" :class="scope.row.platformEnum === platform.platformEnum ? 'text-price' : ''">
                <i class="iconfont mr-5" :class="`icon-${platform.platformEnum.toLowerCase()}`"></i>
                <span>{{ monetaryUnit() }}{{ currencyTransform(platform.price) }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="价格" width="310">
      <template #default="scope">
        <div class="">
          <div class="text-red">
            <span class="text-16">{{ scope.row.sellRate }}</span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column :fixed="'right'" label="涨跌" width="200">
      <template #default="scope">
        <div class="text-right text-gray">
          <div class="mb-10">Steam: {{ dayjs(scope.row.steamUpdateTime * 1000).fromNow() }}</div>
          <div>{{ $t('最优平台') }}: {{ dayjs(scope.row.updateTime * 1000).fromNow() }}</div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script setup>
import rankLabel from '~/components/common/rankLabel.vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const props = defineProps({
  tableData: {
    type: Array,
    default: () => []
  },
  type: {
    type: String,
    default: ''
  }
})
</script>

<style scoped lang="scss"></style>
