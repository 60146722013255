<template>
  <div>
    <div class="index-container">
      <!-- <div class="banner-container h-250">这是 banner区域</div> -->
      <!--      头部信息-->
      <div class="border-market relative mb-30 flex justify-between overflow-hidden rounded-r-[10px] p-20">
        <div class="flex w-330 gap-10">
          <div>
            <div class="mb-10 flex items-center text-16 font-semibold" @click="showKlinecharts">
              <mytooltip :class="'w-350'" placement="bottom">
                <span class="mr-5 cursor-pointer">{{ $t('大盘指数') }}</span>

                <template #content>
                  <div class="py-15 text-14 text-gray-light">
                    <p>{{ $t('指数的起始点为1000，起始时间为2024年2月5日，每分钟计算一次大盘指数。') }}</p>
                    <p>（{{ $t('为了更准确、全面地反映市场行情，从流通性、存世量、价格等多个维度筛选能真实反映市场趋势的皮肤。将这些皮肤进行指数统计。') }}）</p>
                    <p>{{ $t('计算方法：') }}</p>
                    <p>{{ $t('筛选出真实反映市场趋势的皮肤，以皮肤的全网在售数量赋予相应权重') }}</p>
                    <p>{{ $t('起始市值总和 = 累加每个皮肤起始最低售价*权重') }}</p>
                    <p>{{ $t('今日指数 = 1000点 * 今日市值总和 / 起始市值总和') }}</p>
                  </div>
                </template>
              </mytooltip>
              <!--            <span class="cursor-pointer"><img src="~/assets/images/index/border-arrow.webp" alt="" /></span>-->
              <span v-if="borderData.riseFallType !== 'FLAT' && borderData.riseFallDays > 1" :class="borderData.riseFallType" class="ml-10 flex w-100 items-center">
                <img v-if="borderData.riseFallType === 'UP'" class="mr-5 h-16 w-20" src="~/assets/images/index/up.webp" alt="" />
                <img v-if="borderData.riseFallType === 'DOWN'" class="mr-5 h-16 w-20" src="~/assets/images/index/down.webp" alt="" />
                <span class="text-12">
                  <span v-if="borderData.riseFallDays > 1">
                    {{ borderData.riseFallType === 'UP' ? $t('连涨') : $t('连跌') }}
                  </span>
                  {{ borderData.riseFallDays }}{{ $t('天') }}</span
                >
              </span>
            </div>
            <div class="flex">
              <div>
                <div>
                  <span :class="[borderData?.diffYesterday > 0 ? 'text-color-red' : 'text-color-green']" class="text-color-red mr-7 text-28 font-semibold"
                    ><counter :val="borderData.broadMarketIndex" :decimal-places="2"></counter
                  ></span>
                </div>
                <div>
                  <span :class="[borderData?.diffYesterday > 0 ? 'text-color-red' : 'text-color-green', 'mr-7', 'text-14']"
                    >{{ borderData?.diffYesterday > 0 ? `+` : `-` }}<counter :val="borderData?.diffYesterday * 1" :decimal-places="2"></counter
                  ></span>
                  <span :class="[borderData?.diffYesterdayRatio > 0 ? 'text-color-red' : 'text-color-green', 'mr-7', 'text-14']"
                    >{{ borderData?.diffYesterdayRatio > 0 ? `+` : `-` }}<counter :val="borderData?.diffYesterdayRatio * 1" :decimal-places="2"></counter>%</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <indexChart
              class="inline-block"
              :data="borderData?.historyMarketIndexList"
              :direction="borderData?.diffYesterday"
              :last-day-end="true"
              :x-key="0"
              :y-key="1"
              @click="goSection('BROAD')"
            />
          </div>

          <!-- <ClientOnly>
              <div class="text-color-gray text-14">{{ $t('当前时间') }} ：{{ nowTime }}</div>
            </ClientOnly> -->
        </div>
        <div class="flex w-680 justify-between">
          <div class="w-280">
            <div class="flex justify-between">
              <div>
                <div class="mb-10">
                  <mytooltip placement="bottom" :content="$t('追踪全球用户库存的变动，精准分析得出成交量')">
                    <span class="cursor-pointer text-16 font-semibold">{{ $t('饰品成交额') }}</span>
                  </mytooltip>
                </div>
                <div class="mb-10">
                  <span class="text-16 text-price"
                    ><counter :decimal-places="3" :separator="''" :val="borderData?.todayStatistics?.turnover * 1" :class="'mr-5 text-26'"></counter
                  ></span>
                </div>
                <div>
                  <span class="text-color-gray mr-5 inline-block">{{ $t('昨日') }}</span>
                  <span class="text-16 text-gray">{{ numFormat(currencyTransform(borderData?.yesterdayStatistics?.turnover)) }}</span>
                </div>
              </div>
              <div class="w-120">
                <div class="mb-10 text-16 font-semibold">{{ $t('饰品成交量') }}</div>
                <div class="mb-10">
                  <span class="text-color-primary text-16"
                    ><counter :decimal-places="3" :separator="''" :val="borderData?.todayStatistics?.tradeNum * 1" :class="'mr-5 text-26'"></counter
                  ></span>
                </div>
                <div>
                  <span class="text-14 text-gray"
                    ><span>{{ $t('昨日') }}</span
                    >{{ numFormat(borderData?.yesterdayStatistics?.tradeNum) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <el-divider direction="vertical" style="height: 100%"></el-divider>
          <div class="w-280">
            <!-- <div class="mb-18 text-16 font-semibold">今日新增 / 昨日新增</div> -->
            <div class="flex justify-between">
              <div>
                <div class="mb-10">
                  <mytooltip placement="bottom" :content="$t('开箱、汰换、游戏掉落的方式产生的皮肤数量和价值')">
                    <span class="cursor-pointer text-16 font-semibold">{{ $t('饰品新增额') }}</span>
                  </mytooltip>
                </div>
                <div class="mb-10">
                  <span class="text-16 text-price"
                    ><counter :decimal-places="3" :separator="''" :val="borderData?.todayStatistics?.addValuation * 1" :class="'mr-5 text-26'"></counter
                  ></span>
                </div>
                <div>
                  <span class="text-color-gray mr-5 inline-block">{{ $t('昨日') }}</span>
                  <span class="text-14 text-gray"
                    ><counter :decimal-places="3" :separator="''" :val="currencyTransform(borderData?.yesterdayStatistics?.addValuation * 1)"></counter
                  ></span>
                </div>
              </div>
              <div>
                <div class="mb-10 text-16 font-semibold">{{ $t('饰品新增量') }}</div>
                <div class="mb-10">
                  <span class="text-color-primary text-16"
                    ><counter :decimal-places="3" :separator="''" :val="borderData?.todayStatistics?.addNum * 1" :class="'mr-5 text-26'"></counter
                  ></span>
                </div>
                <div>
                  <span class="text-gray">{{ $t('昨日') }}</span>
                  <span class="text-14 text-gray"
                    ><counter :decimal-places="3" :separator="''" :val="currencyTransform(borderData?.yesterdayStatistics?.addNum * 1)"></counter
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-divider direction="vertical" style="height: 110px"></el-divider>
        <div class="w-270">
          <div class="mb-10">
            <mytooltip
              :class="'w-300'"
              placement="bottom"
              :content="$t('饰品存世量仅统计匕首、手套、步枪、手枪、微型冲锋枪、霰弹枪和机枪的饰品数量，印花、武器箱等不含磨损度的物品将不会被计入存世量统计中。')"
            >
              <span class="cursor-pointer text-16 font-semibold">{{ $t('饰品存世量') }}</span>
            </mytooltip>
          </div>
          <div class="mb-10">
            <span class="inline-block text-left text-28 font-semibold" :style="{ width: `${borderData?.surviveNum?.length * 20}px` }"
              ><counter :val="borderData?.surviveNum * 1" :need-format="false" :use-easing="false"></counter
            ></span>
            <span class="text-color-gray text-14">{{ $t('件') }} </span>
          </div>
          <div class="text-color-gray text-14">{{ $t('拥有饰品的人数') }}:<counter :val="borderData?.holdersNum * 1" :need-format="false"></counter> {{ $t('人') }}</div>
        </div>
        <div class="absolute top-0 h-150 bg-white pl-54 transition-all" :class="showOnline ? 'right-0 shadow' : 'right-[-1040px]'" style="width: 1060px">
          <div
            class="absolute left-0 top-20 h-105 w-24 cursor-pointer items-center py-3 text-center text-white"
            :class="showOnline ? 'rounded-r-[10px] bg-[#B3CBF4] pt-25' : 'rounded-l-[10px] bg-blue'"
            @click="onLineSwitch"
          >
            {{ showOnline ? $t('收起') : $t('在线人数') }} <i v-if="!showOnline" class="iconfont icon-xiangzuozhankai relative text-8" style="top: -5px"> </i>
            <i v-else class="iconfont icon-xiangyouzhankai relative text-8" style="top: -5px"></i>
          </div>
          <div class="flex h-20 w-1000 justify-between py-20">
            <div>
              <div class="flex w-280 rounded-[10px] bg-blue-50 p-10">
                <div class="w-120">
                  <div class="mb-16">{{ $t('当前在线人数') }}</div>
                  <div class="mb-14">{{ $t('昨日同时段') }}</div>
                  <div class="">{{ $t('上周同时段') }}</div>
                </div>
                <div class="text-16">
                  <div class="mb-12 text-blue">{{ onLineRes?.count }}</div>
                  <div class="mb-11">
                    {{ onLineRes?.yesterdayHour }}
                    <span class="ml-5" :class="onLineRes?.yesterdayHourRatio > 0 ? 'text-red' : 'text-green'">{{ onLineRes?.yesterdayHourRatio }}%</span>
                  </div>
                  <div>
                    {{ onLineRes?.lastWeekHour }}
                    <span class="ml-5" :class="onLineRes?.lastWeekHourRatio > 0 ? 'text-red' : 'text-green'">{{ onLineRes?.lastWeekHourRatio }}%</span>
                  </div>
                </div>
              </div>
              <!--              <div class="text-gray">更新时间：{{ dayjs(onLineRes?.updateTime * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>-->
            </div>
            <div>
              <div class="online-chart-dom h-110 w-675"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-20 flex gap-10">
        <div v-for="(section, index) in sectionData">
          <sections :k="index" :data="section"></sections>
        </div>
      </div>
      <!-- 头部信息结束 -->
      <!--收藏夹开始-->
      <div class="mb-20">
        <collect></collect>
      </div>

      <!--收藏夹结束-->
      <!--  排行榜    -->
      <div class="mb-20">
        <indexLadders></indexLadders>
      </div>
      <!--  排行榜结束-->

      <!--新增饰品开始-->
      <div class="new-skin mb-30">
        <el-tooltip class="box-item" effect="dark" :content="$t('开箱、汰换、游戏掉落的方式产生的饰品数量和价值')" :popper-class="'w-300'">
          <span class="skin-title text-16 font-bold text-[#252525]">{{ $t('最近新增饰品') }}</span>
        </el-tooltip>

        <el-skeleton style="width: 100%" :animated="true" :loading="skinData.list.length <= 0">
          <template #template>
            <div>
              <el-skeleton-item v-for="i in 6" :key="i" variant="rect" style="width: 220px; height: 210px; margin-right: 10px; margin-top: 20px; display: inline-block" />
            </div>
          </template>
          <template #default>
            <ClientOnly>
              <div class="skin-list py-20" @mouseenter="enterSkin" @mouseleave="leaveSkin">
                <div id="J_SkinWrapper" class="skin-wrapper" :class="skinData.bIsAnimation ? 'animation' : ''">
                  <a
                    v-for="(item, index) in skinData.list"
                    :key="index"
                    class="skin-item relative mr-16 h-210 w-220 cursor-pointer"
                    :href="localePath(`/inventory/${item.steamAccount.steamToken}?&hash=${item.asset.assetInfo.fingerprint}`)"
                  >
                    <itemCard :item="item.asset" :common-dictionary="commonDictionary" />
                    <div class="item-user">
                      <el-avatar :size="50" :src="item.steamAccount.avatar">
                        <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                      </el-avatar>
                      <p class="mt-6 text-16 font-bold">{{ item.steamAccount.nickname }}</p>
                      <p class="mt-5 flex items-center justify-center">
                        <i class="iconfont icon-jiantou text-color-lightgray mr-5"></i>
                        <span class="text-color-lightgray text-12">{{ $t('前往库存查看') }} </span>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </ClientOnly>
          </template>
        </el-skeleton>
      </div>
      <!--新增饰品结束-->

      <!--关键特性和工具开始-->
      <div class="rounded-[10px] bg-white p-20">
        <div class="mb-20 text-16 font-semibold">{{ $t('关键特性和工具') }}</div>
        <div class="flex gap-10">
          <a v-for="tool in toolList" :href="tool.link">
            <div class="tool-item relative h-105 w-264 cursor-pointer rounded-[10px] pl-20 pt-25">
              <div class="mb-15 text-20 font-medium text-white">{{ tool.title }}</div>
              <div class="text-white">{{ tool.desc }}</div>
              <div class="absolute right-10 top-5">
                <img :src="tool.img" alt="" />
              </div>
              <div
                class="arrow absolute bottom-0 right-0 flex h-32 w-32 items-center justify-center rounded-br-[10px] rounded-tl-[10px] border-l-2 border-t-2 border-white bg-[#B3CBF4]"
              >
                <i class="iconfont icon-xiangyou text-white"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!--关键特性和工具结束-->
    </div>

    <client-only>
      <div v-if="klinechartsVisible">
        <klinecharts :dialog-visible="klinechartsVisible" @close="klinechartsVisible = false" />
      </div>
    </client-only>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { getNewestItems, getOnlineInfo, getTotalData } from '~/api/steam'
import { getSectionData } from '~/api/section'
import { getCommonDictionary } from '~/api/common'
import indexChart from './indexcomponents/indexChart.vue'
import counter from './indexcomponents/counter.vue'
import klinecharts from './indexcomponents/klinecharts.vue'
import itemCard from '~/components/common/itemCard.vue'
import wsConnect from '~/composables/websocket'
import mytooltip from '~/components/common/mytooltip.vue'
import sections from './allnewIndexComponents/sections.vue'
import indexLadders from './allnewIndexComponents/indexLadders.vue'
import collect from './indexcomponents/collect.vue'
import dayjs from 'dayjs'
import * as echarts from 'echarts/core.js'
import { DataZoomComponent, GridComponent, TooltipComponent, VisualMapComponent } from 'echarts/components.js'
import { LineChart } from 'echarts/charts.js'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers.js'
import { UniversalTransition } from 'echarts/features.js'

const { t } = useI18n()
const $t = t

const localePath = useLocalePath()
// 更改页面模板

useHead({
  title: $t('SteamDT-CS2饰品数据_CS2市场大盘_CS2饰品市场_CS2饰品排行_CS2自定义大盘'),
  meta: [
    {
      name: 'description',
      content: $t(
        'SteamDT为您提供CS2饰品走势、CS2饰品自定义大盘、饰品排行榜、饰品全网比价、饰品成交数据、库存快照数据、热门板块走势数据、CS2饰品挂刀比例及价格走势等数据，更多CS2饰品数据就上SteamDT。'
      )
    },
    {
      name: 'keywords',
      content: $t(
        'CS2市场, CSGO市场, CS2饰品市场,CS2市场大盘,CS2市场大盘数据分析,CS2饰品数据,CS2饰品趋势榜,CS2饰品涨跌榜,CS2饰品存世量,CS2饰品热度,CS2成交数据,CS2热门板块,CS2自定义大盘'
      )
    }
  ]
})

let borderData = ref({})
let categoryData = ref({})
const sectionData = ref({})
let nowTime = ref('')
let historyMarketIndexList = ref([])
const showOnline = ref(false)
const skinData = reactive({
  // 源数据
  list: [],
  // 新增饰品数据
  newList: [],
  index: 0,
  limit: 20,
  timer: null,
  bIsAdd: false,
  bIsAnimation: false
})
const klinechartsVisible = ref(false)

const [{ data: borderRes }, { data: onLineRes }] = await Promise.all([getTotalData(), getOnlineInfo()])
borderData.value = borderRes
// skinData.list = newestItems
// historyMarketIndexList.value = borderData.value.historyMarketIndexList.sort(function (a, b) {
//   const aTime = dayjs(a[0]).valueOf()
//   const bTime = dayjs(b[0]).valueOf()
//   return aTime - bTime
// })
// categoryData.value = categoryRes
nowTime.value = dayjs().format('YYYY-MM-DD HH:mm:ss')

const { data: commonDictionary } = await useLazyAsyncData(getCommonDictionary, {
  server: false
})

// let { data: borderData } = await getTotalData();
// let { data: categoryData } = await getCategoryData();

// console.log("borderData", borderData);
// console.log("categoryData", categoryData);
// console.log("pageData", pageData);

onMounted(async () => {
  // setInterval(() => {
  //   getBorderData()
  //   // getcategorydata()
  // }, 60000)
  setInterval(() => {
    nowTime.value = dayjs().format('YYYY-MM-DD HH:mm:ss')
  }, 1000)
  const { data: newestItems } = await getNewestItems()
  skinData.list = newestItems
  // 插入新饰品
  startAddSkin()

  // 监听动画停止
  setTimeout(() => {
    document.getElementById('J_SkinWrapper')?.addEventListener('animationend', () => {
      skinData.bIsAnimation = false
    })
  }, 200)

  await wsConnect.init('', [
    {
      key: 'NEW_FINGERPRINT_ASSET',
      callBack: function (event) {
        const curEvaluatePrice = Number(event.data.asset.assetInfo.evaluatePrice) * Number(monetaryRate())
        event.data.asset.assetInfo.evaluatePrice = curEvaluatePrice.toFixed(2)
        skinData.newList.push(event.data)
      }
    },
    {
      key: 'STEAM_TOTAL_STATISTICS_PUSH',
      callBack: function (event) {
        // console.log('ws event', event)
        // if (event.data?.todayStatistics) {
        //   event.data.todayStatistics.addValuation = Number(event.data.todayStatistics?.addValuation) * Number(monetaryRate())
        //   event.data.todayStatistics.turnover = Number(event.data.todayStatistics?.turnover) * Number(monetaryRate())
        // }

        // if (event.data?.yesterdayStatistics) {
        //   event.data.yesterdayStatistics.addValuation = Number(event.data.yesterdayStatistics?.addValuation) * Number(monetaryRate())
        //   event.data.yesterdayStatistics.turnover = Number(event.data.yesterdayStatistics?.turnover) * Number(monetaryRate())
        // }
        const temp = Object.assign(borderData.value, event.data)
        borderData.value = temp
      }
    }
  ])
  echartInit()
  const { data } = await getSectionData()
  sectionData.value = data
})
function getBorderData() {
  getTotalData().then(res => {
    borderData.value = res.data
    // historyMarketIndexList.value = borderData.value.historyMarketIndexList.sort(function (a, b) {
    //   const aTime = dayjs(a.calculateTime).valueOf()
    //   const bTime = dayjs(b.calculateTime).valueOf()
    //   return aTime - bTime
    // })
  })
}

// 移入饰品列表
function enterSkin() {
  stopAddSkin()
}
// 移出饰品列表
function leaveSkin() {
  const { bIsAdd } = skinData

  if (!bIsAdd) {
    startAddSkin()
  }
}
// 开启插入轮询
function startAddSkin() {
  const { newList } = skinData

  skinData.timer = setInterval(() => {
    if (skinData.index <= newList.length - 1) {
      addSkin(skinData.index)
      skinData.index++
      skinData.bIsAdd = false
    } else {
      // stopAddSkin()
      skinData.bIsAdd = true
    }

    // 监听动画停止
    // setTimeout(() => {
    //   skinData.bIsAnimation = false
    // }, 1000)
  }, 3000)
}
// 停止插入轮询
function stopAddSkin() {
  clearInterval(skinData.timer)
  skinData.timer = null
}
// 插入列表
function addSkin(curIndex) {
  const { list, newList, limit } = skinData

  if (list.length >= limit) {
    skinData.list.pop()
  }
  skinData.list.unshift(newList[curIndex])
  skinData.bIsAnimation = true
}

// 链接跳转
function goLink(url) {
  const router = useRouter()
  router.push(url)
}

// 显示KLineChart
function goSection(type) {
  const localePath = useLocalePath()
  location.href = localePath(`/section/?type=${type}`)
}

function onLineSwitch() {
  showOnline.value = !showOnline.value
}

const echartInit = () => {
  const dateList = onLineRes.allHistory.map(item => {
    return item[0]
  })
  const valueList = onLineRes.allHistory.map(item => {
    return item[2]
  })
  echarts.use([GridComponent, VisualMapComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent, DataZoomComponent, SVGRenderer])
  const dom = document.querySelector('.online-chart-dom')
  const myChart = echarts.init(dom, null, {
    renderer: 'svg'
  })
  const option = {
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      top: '10px',
      bottom: 0,
      // bottom: '3%',
      containLabel: true
    },
    dataZoom: [
      {
        show: false,
        realtime: true,
        start: 50,
        end: 100,
        xAxisIndex: [0, 1]
      },
      {
        type: 'inside',
        realtime: true,
        start: 95,
        end: 100,
        xAxisIndex: [0, 1]
      }
    ],
    xAxis: [
      {
        type: 'category',

        boundaryGap: false,
        data: dateList
      }
    ],
    yAxis: [
      {
        type: 'value',
        position: 'left',
        min: 'dataMin',
        splitNumber: 3
      }
    ],
    series: [
      {
        name: '峰值',
        type: 'line',
        // stack: '总量',
        showSymbol: false,
        // smooth: 0.3,
        lineStyle: {
          width: 1
        },
        // areaStyle: {},
        data: valueList,
        areaStyle: {
          opacity: 0.2
        }
      }
    ]
  }
  myChart.setOption(option)
}
const toolList = [
  {
    title: $t('全网比价'),
    desc: $t('国内外饰品价格比较'),
    img: 'https://cdn.ok-skins.com/steam-stock/new-index/mkt.webp',
    link: localePath('/mkt')
  },
  {
    title: $t('饰品追踪'),
    desc: $t('通过磨损值追踪饰品所在库存'),
    img: 'https://cdn.ok-skins.com/steam-stock/new-index/tracker.webp',
    link: localePath('/cs2/tracker')
  },
  {
    title: $t('库存估值'),
    desc: $t('支持查询CS2任意日期库存信息'),
    img: 'https://cdn.ok-skins.com/steam-stock/new-index/mkt.webp',
    link: localePath('/inventory')
  },
  {
    title: $t('自定义饰品大盘'),
    desc: $t('饰品收藏夹即自定义饰品大盘'),
    img: 'https://cdn.ok-skins.com/steam-stock/new-index/mkt.webp',
    link: localePath('/my/collect')
  },
  {
    title: $t('饰品DIY'),
    desc: $t('支持武器自定义印花并检视'),
    img: 'https://cdn.ok-skins.com/steam-stock/new-index/mkt.webp',
    link: localePath('/skindiy')
  }
]
</script>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');

.index-container {
  z-index: 2;
  margin-top: -15px;
  .banner-container {
    // margin-top: -59px;
    color: red;
    background-color: #000;
  }
  .border-market {
    // background: #f8faff;
    // background: url(~/assets/images/index/border-market-bg.png) center no-repeat;
    // box-shadow: 0px -3px 0px 0px #acc6ff;
    border-radius: 8px;
    background-color: #f7f9ff;
    .UP {
      color: #ff0020;
    }
    .DOWN {
      color: #0dab62;
    }
  }
  .category-data-container {
    .category-data-item {
      background: #fff;
    }
  }

  .new-skin {
    .skin-list {
      width: 1420px;
      overflow-x: hidden;
      position: relative;
      left: -10px;
      padding-left: 10px;
    }

    .skin-wrapper {
      width: 23600px;
      font-size: 0;
      position: relative;

      &.animation {
        animation: move 1s ease;
      }
    }

    .skin-item {
      display: inline-block;
      overflow: hidden;
      border-radius: 10px;
      transition: all 0.3s;
      .item-user {
        width: 100%;
        text-align: center;
        height: 110px;
        background: $white-color;
        // box-shadow: 0px -3px 0px 0px #acc6ff;
        border-radius: 10px;
        position: absolute;
        left: 0;
        bottom: -138px;
        transition: bottom 0.3s;

        .el-avatar {
          margin: -25px auto 0;
        }
      }
      &:hover {
        box-shadow:
          rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
          rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
          rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
      }
      &:hover .item-user {
        bottom: 0;
        box-shadow:
          rgba(0, 0, 0, 0.16) 0px 1px 2px -2px,
          rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
          rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
      }
    }

    @keyframes move {
      0% {
        left: -236px;
      }
      100% {
        left: 0;
      }
    }
  }
}

.tool-item {
  background: linear-gradient(63deg, #4087ff 0%, #b0d0ff 100%);

  .arrow {
    transition: all linear 0.3s;
  }
  &:hover {
    .arrow {
      background: linear-gradient(180deg, #058aee 0%, #0252d9 100%);
    }
  }
}
</style>
