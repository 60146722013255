<template>
  <el-table v-show="tableData?.length > 0" :data="tableData" :show-header="false" style="width: 100%" :row-style="{ height: '122px' }" @row-click="changeActionFolder">
    <el-table-column label="排名" width="50">
      <template #default="scope">
        <div class="text-center"><rankLabel :rank="scope.$index + 1"></rankLabel></div>
      </template>
    </el-table-column>
    <el-table-column label="名称" width="800">
      <template #default="scope">
        <div class="flex items-center gap-10">
          <div class="h-74 w-74 rounded-[10px]">
            <img class="rounded-[10px]" :src="scope.row?.userAvatar" alt="" />
          </div>
          <div>
            <div class="mb-10 text-black">{{ scope.row.folderName }}</div>
            <div class="flex gap-30">
              <div>{{ $t('创建者') }}: {{ scope.row.username }}</div>
              <div>{{ $t('饰品件数') }}: {{ scope.row.folderCount }}</div>
              <div>{{ $t('今日收藏夹涨跌') }}: {{ (scope.row.trend?.riseFallRate * 100).toFixed(2) }}%</div>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="价格" width="310">
      <template #default="scope">
        <div v-if="type === 'hotCount'" class="">
          <div class="text-red">
            <i class="iconfont icon-huo"></i>
            <span>{{ scope.row.hotCount }}</span>
          </div>
          <div class="text-gray">
            <span>{{ $t('热度排名') }} #{{ scope.row.hotRank || '200+' }}</span>
            <span v-if="scope.row.hotRankChange !== 0">/ {{ scope.row.hotRankChange > 0 ? $t('近期上升') : $t('近期下降') }} {{ scope.row.hotRankChange }} {{ $t('名') }}</span>
            <span v-if="scope.row?.hotRankChange === 0 && scope.row?.hotRankKeepDays >= 3">{{ $t('持续{days}天', { days: scope.row.hotRankKeepDays }) }}</span>
          </div>
        </div>
        <div v-else class="">
          <div :class="[scope.row.hotRankChange > 0 ? 'text-red' : '', scope.row.hotRankChange < 0 ? 'text-green' : '']">
            <span class="mr-5"
              ><i v-if="scope.row.hotRankChange > 0" class="iconfont icon-lianxushangsheng"></i><i v-if="scope.row.hotRankChange < 0" class="iconfont icon-lianxuxiajiang"></i>
              {{ scope.row.hotRankChange === 0 ? '-' : '' }}
            </span>
            <span>{{ scope.row.hotRankChange }}</span>
          </div>
          <div class="text-gray">
            <span class="mr-10">{{ $t('热度排名') }} #{{ scope.row.hotRank || '200+' }}</span>
            <span>{{ $t('当前热度') }}: <i class="iconfont icon-huo"></i> {{ scope.row.hotCount }}</span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column :fixed="'right'" label="涨跌" width="200">
      <template #default="scope">
        <div class="text-right">
          <div>{{ $t('更新日期') }}: {{ dayjs(scope.row.modifyTime * 1000).format('YYYY-MM-DD') }}</div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <client-only>
    <collectDetail :visible="dialogVisible" :folder="actionFolder" from="index" :platform="'YOUPIN'" @close="dialogVisible = false"></collectDetail>
  </client-only>
</template>
<script setup>
import rankLabel from '~/components/common/rankLabel.vue'
import collectDetail from '~/pages/my/collect/components/collect-detail.vue'
import dayjs from 'dayjs'

const props = defineProps({
  tableData: {
    type: Array,
    default: () => []
  },
  type: {
    type: String,
    default: ''
  }
})

const dialogVisible = ref(false)
const actionFolder = ref({})

const changeActionFolder = folder => {
  console.log('folder', folder)
  actionFolder.value = folder
  dialogVisible.value = true
}
</script>
<style scoped lang="scss"></style>
