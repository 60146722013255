<template>
  <div :id="id" :style="{ width: `${width}px`, height: `${height}px` }" class="my-chart"></div>
</template>

<script setup>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { render } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default: ``
  },
  width: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 150
  },
  color: {
    type: String,
    default: '#0252D9'
  },
  name: {
    type: String,
    default: ''
  },
  data: {
    type: Array,
    default: function () {
      return []
    }
  }
})

onMounted(() => {
  nextTick(() => {
    echartInit(props.id)
  })
  // echartInit(props.id)
})

let myChart = null

const echartOption = ref(null)

const echartInit = id => {
  echarts.use([TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, SVGRenderer])
  const chartDom = document.getElementById(`${id}`)
  myChart = echarts.init(chartDom, null, {
    renderer: 'svg'
  })
  echartOption.value = {
    grid: {
      left: '10%', // 调整左侧边距
      right: '10%', // 调整右侧边距
      top: '10%', // 调整顶部边距
      bottom: '10%' // 调整底部边距
    },
    // legend: {
    //   data: ['日线', 'MA7', 'MA14', 'MA21'],
    //   top: 15,
    //   right: 20,
    //   textStyle: {
    //     color: '#979CAE'
    //   }
    // },
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      renderMode: 'html'
      // position: 'bottom'
    },
    textStyle: {
      fontSize: 14,
      color: '#979CAE'
    },
    xAxis: [
      {
        type: 'time',
        show: false,
        boundaryGap: false
      }
    ],
    yAxis: [
      {
        type: 'value',
        boundaryGap: [0, '10%'],
        min: 'dataMin',
        max: 'dataMax',
        show: false
      }
    ],

    series: [
      {
        name: props.name || null,
        type: 'line',
        smooth: false,
        showSymbol: false,
        symbol: 'circle',
        smooth: 0.2,
        symbolSize: 10,
        lineStyle: { width: 1 },
        color: props.color,
        areaStyle: {
          color: props.color,
          opacity: 0.05
        },
        // emphasis: {
        //   focus: 'series'
        // },
        data: props.data
      }
    ]
  }
  echartOption.value && myChart.setOption(echartOption.value)
  window.addEventListener('resize', () => {
    myChart.resize() // 重新计算图表的布局
  })
}
</script>

<style lang="scss">
.my-chart {
  // position: inherit !important;
}
</style>
